import { HuiSelect } from 'handle-ui';
import React, { useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import toastr from 'toastr';
import { getPayment } from '../../../../actions/payment/getPayment';
import { resetInvoices } from '../../../../redux/InvoicesSlice';
import { forceCloseLoading, openLoading } from '../../../../redux/LoadingSlice';
import { closeModal, openModal } from '../../../../redux/ModalSlice';
import { resetPaymentData, selectPaymentData } from '../../../../redux/PaymentDataSlice';
import { updatePayment } from '../../../../redux/PaymentSlice';
import { store } from '../../../../store/store';
/* eslint import/no-webpack-loader-syntax: off */
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { createPayment } from '../../../../actions/payment/createPayment';
import { resetCredits } from '../../../../redux/CreditsSlice';
import { resetInvoicesPayment } from '../../../../redux/InvoicesPaymentSlice';
import { selectPaymentReasons } from '../../../../redux/PaymentReasonsSlice';
import { selectPortalInfo } from '../../../../redux/PortalInfoSlice';
import { checkIfShortpayEnabled } from '../../../../utils/utilCheckIfPaymentHasError';
import UtilPaymentMethods from '../../../../utils/utilPaymentMethods';
import { paymentOriginOptions } from '../../../../utils/utilsPaymentOriginOptions';
import {
  checkPaymentFormForError,
  generateIdempotencyKey,
  hasPaymentReasons,
  prepareInvoicesToPay,
} from './utilPaymentForm';

const HuiPaymentWithCredits = () => {
  const { pathname } = useLocation();
  const portalInfo = useSelector(selectPortalInfo);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const paymentData = useSelector(selectPaymentData);
  const paymentReasons = useSelector(selectPaymentReasons);

  const { settings: { isShortpayEnabled = false, isShortpayAdminEnabled = false, isOverpayEnabled = false } = {} } =
    portalInfo || {};

  const _isShortpayEnabled = checkIfShortpayEnabled(isShortpayEnabled, isShortpayAdminEnabled);

  const payment = {
    invoices: paymentData.paymentInvoices,
    subtotal: paymentData.subtotal,
    hasOverpayReasons: hasPaymentReasons(paymentReasons, 'overpay'),
    hasShortpayReasons: hasPaymentReasons(paymentReasons, 'shortpay'),
    paymentMethod: UtilPaymentMethods.CREDIT,
  };

  const isSlim = pathname.endsWith('-slim');
  const isWithinIframe = window.parent !== window;
  const isRequestWaiver = !!searchParams.get('requestWaiver');

  const [fields, setFields] = useState({
    origin: '',
  });
  const [idempotencyKey] = useState(generateIdempotencyKey());

  const formSubmitHandler = async (e) => {
    e.preventDefault();
    if (hasPaymentError()) {
      toastr.warning(`Please review the invoices to proceed.`);
      return;
    }
    store.dispatch(openLoading());
    try {
      await preparePayment();
    } catch (e) {
      console.log('Error processing payment token', e);
    }
  };

  async function preparePayment() {
    const payload = await createPaymentPayload();
    await processPayment(payload);
  }

  async function createPaymentPayload() {
    const paymentData = await store.getState().paymentData;

    const { subtotal, credit, balance, currencyCode = 'USD' } = paymentData;

    const total = subtotal - credit - balance;

    const paymentJson = {
      paymentType: UtilPaymentMethods.CREDIT,
      subtotal: parseFloat(subtotal),
      balance: parseFloat(balance),
      credit: parseFloat(credit),
      total: total,
      currencyCode,
      invoices: prepareInvoicesToPay(paymentData.paymentInvoices),
      info: paymentData.info,
      recurrence: {},
      origin: isEmpty(fields.origin) ? null : fields.origin,
      token: UtilPaymentMethods.CREDIT,
    };
    return paymentJson;
  }

  async function processPayment(payment) {
    try {
      const submitPayment = await createPayment(payment, idempotencyKey);
      const { code } = submitPayment;
      await paymentSuccess(code);
    } catch (e) {
      store.dispatch(forceCloseLoading({}));
      const modal = {
        type: 'alerts',
        title: 'Error processing payment',
        message: e.message,
        confirmLabel: 'Ok, I will fix  it',
        confirmAction: () => store.dispatch(closeModal()),
        showConfirmButton: true,
      };
      store.dispatch(openModal(modal));
    }
  }

  async function paymentSuccess(code) {
    try {
      const result = await getPayment(code);
      await store.dispatch(updatePayment(result));
      await store.dispatch(forceCloseLoading({}));
      await redirectToThanksPage(code);
    } catch (e) {
      toastr.error('Error processing payment');
    }
  }

  async function redirectToThanksPage(code) {
    let path = isSlim ? `/thanks-slim/${code}` : `/thanks/${code}`;
    if (isRequestWaiver) {
      path += `?requestWaiver=true`;
    }
    navigate(path);
    await resetPayment();
  }

  async function resetPayment() {
    await store.dispatch(resetPaymentData({}));
    await store.dispatch(resetInvoices({}));
    await store.dispatch(resetInvoicesPayment({}));
    await store.dispatch(resetCredits({}));
  }

  function hasPaymentError() {
    return checkPaymentFormForError(fields, payment, { isShortpayEnabled: _isShortpayEnabled, isOverpayEnabled });
  }

  return (
    <form onSubmit={formSubmitHandler} method="post" id="payment-form">
      <div hidden={!isWithinIframe} className="mt-4">
        <div className="row">
          <div className="col-12">
            <HuiSelect
              topLabel
              required={isWithinIframe}
              size="large"
              value={fields.origin}
              id="origin"
              name="origin"
              label="Request Method"
              onChange={handleChange}
              options={paymentOriginOptions}
            />
          </div>
        </div>
      </div>

      <div className="button-container mt-4">
        <button className="hui-btn hui-btn-green hui-btn-xl w-100" disabled={hasPaymentError()}>
          Submit Payment
        </button>
      </div>
    </form>
  );

  function handleChange(e) {
    setFields({ ...fields, [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value });
  }
};

export default HuiPaymentWithCredits;
