import { HuiIcon } from 'handle-ui';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { selectPaymentData } from '../../../../redux/PaymentDataSlice';
import { selectPortalInfo } from '../../../../redux/PortalInfoSlice';
import UtilPaymentMethods from '../../../../utils/utilPaymentMethods';
import { formatCurrency } from '../../../../utils/utilsCurrency';
import HuiSelectPaymentType from './HuiSelectPaymentType';

const HuiSelectPayment = (props) => {
  const portalInfo = useSelector(selectPortalInfo);
  const paymentData = useSelector(selectPaymentData);

  const { credit, subtotal, isPrepaid, currencyCode } = paymentData;

  const { selectAch, selectCard, selectCredit } = props;

  const isAchEnabled = get(portalInfo, 'settings.isAchEnabled') && currencyCode === 'USD';
  const isCreditCardEnabled = get(portalInfo, 'settings.isCreditCardEnabled');
  const isPayWithCreditEnabled = get(portalInfo, 'settings.isPayWithCreditEnabled');

  const isPayWithBalanceEnabled = credit > 0 && subtotal === credit && !isPrepaid;

  const checkoutOptions = [isAchEnabled, isCreditCardEnabled, isPayWithBalanceEnabled && isPayWithCreditEnabled];
  const countCheckoutOptions = checkoutOptions.filter(Boolean).length;

  const selectPaymentTypeClass = `hui-select-payment hui-select-payment-options-${countCheckoutOptions} mx-auto`;

  const { settings: { isSurchargeEnabled = false, creditCardBrands = [], creditCardDescription } = {} } = portalInfo;

  const descriptionAch = 'All major U.S. banks supported';
  const descriptionCard = (
    <>
      <div className="hui-select-payment-card d-flex align-items-center justify-content-center">
        <i className="hui-i hui-svg-logo-visa mx-1" hidden={!creditCardBrands.includes('visa')}></i>
        <i className="hui-i hui-svg-logo-mastercard mx-1" hidden={!creditCardBrands.includes('mastercard')}></i>
        <i className="hui-i hui-svg-logo-amex mx-1" hidden={!creditCardBrands.includes('amex')}></i>
        <i className="hui-i hui-svg-logo-discovery mx-1" hidden={!creditCardBrands.includes('discover')}></i>
      </div>
      {creditCardDescription}
    </>
  );

  const creditFormatted = formatCurrency(paymentData?.credit, currencyCode);

  return (
    <div className={selectPaymentTypeClass}>
      <HuiSelectPaymentType
        icon={<HuiIcon name="bank" size="md" />}
        title="ACH"
        disabled={isPayWithBalanceEnabled}
        description={descriptionAch}
        buttonLabel="Pay With ACH"
        onClick={selectAch}
        hidden={!isAchEnabled}
        type={UtilPaymentMethods.ACH}
      />
      <HuiSelectPaymentType
        icon={<HuiIcon name="card" size="md" />}
        title="Credit or Debit Card"
        disabled={isPayWithBalanceEnabled}
        description={descriptionCard}
        buttonLabel="Pay With Credit or Debit Card"
        onClick={selectCard}
        hidden={!isCreditCardEnabled}
        type={UtilPaymentMethods.CREDIT_CARD}
      />
      <HuiSelectPaymentType
        hidden={!isPayWithBalanceEnabled || !isPayWithCreditEnabled}
        isPayWithBalanceEnabled={isPayWithBalanceEnabled && isPayWithCreditEnabled}
        icon={<HuiIcon name="card" size="md" />}
        title="Pay With Credits"
        description={`You paying ${creditFormatted} with credits`}
        buttonLabel="Process payment with credits"
        onClick={selectCredit}
        type={UtilPaymentMethods.CREDIT}
      />
    </div>
  );
};

export default HuiSelectPayment;
