import { Tooltip } from '@mui/material';
import { HuiIcon } from 'handle-ui';
import React from 'react';
import { formatCurrency } from '../../../utils/utilsCurrency';

const PaymentTotal = (props) => {
  const { title, description, amount, hidden, currencyCode } = props;

  const amountFormatted = formatCurrency(amount, currencyCode);

  const amountClass = `hui-subtitle ${amount === 0 ? 'text-end' : ''}`;
  return (
    !hidden && (
      <>
        <div className="hui-text-lg d-flex align-items-center justify-content-end">
          {title}

          {!!description && (
            <Tooltip arrow placement="top" title={description}>
              <div className="d-flex ms-2">
                <HuiIcon size="xs" name="info" />
              </div>
            </Tooltip>
          )}
        </div>

        <div className={amountClass}>{amountFormatted}</div>
      </>
    )
  );
};

export default PaymentTotal;
