import { HuiIcon, HuiLoading, HuiPanel } from 'handle-ui';
import { size } from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAllRecurrences } from '../../../actions/recurrence/getAllRecurrences';
import EmptyComponent from '../../../components/general/EmptyComponent';
import { selectCurrencyCode } from '../../../redux/CurrencyCodeSlice';
import { selectLoading } from '../../../redux/LoadingSlice';
import DashboardRecurrencesItem from './DashboardRecurrencesItem';

const DashboardRecurrences = () => {
  const loading = useSelector(selectLoading);
  const currencyCodeSelector = useSelector(selectCurrencyCode);
  const [recurrences, setRecurrences] = useState({
    currencyCode: null,
    data: [],
  });
  const [isLoading, setIsLoading] = useState(true);
  const currencyCode = currencyCodeSelector.currencyCode;

  useEffect(() => {
    async function findRecurrences(currencyCode) {
      try {
        setRecurrences({
          currencyCode: currencyCode,
          data: ['loading'],
        });
        const result = await getAllRecurrences({ status: 'PENDING', size: 5, currencyCode });
        setRecurrences({
          currencyCode: currencyCode,
          data: result?.rows,
        });
        setIsLoading(!size(result));
      } catch (error) {
        setRecurrences({
          currencyCode: currencyCode,
          data: ['error'],
        });
      }
    }

    if (!loading && currencyCode && recurrences.currencyCode !== currencyCode) {
      findRecurrences(currencyCode);
    }
  }, [loading, recurrences, setRecurrences, currencyCode]);

  const recurringPayments = recurrences.data?.map((recurrence, i) => {
    return <DashboardRecurrencesItem key={i} recurrence={recurrence} />;
  });

  const isEmpty = size(recurrences.data) === 0;

  const createRecurrence = (
    <EmptyComponent
      className="pb-3"
      title="Create Recurrence"
      subtitle="Automate Payments with Recurrence Feature"
      buttonTitle="Create Recurrence"
      link="/prepay"
    />
  );

  return (
    <>
      <HuiPanel className="overflow-hidden position-relative pt-3 hui-text-lg d-flex flex-column flex-grow-1">
        <div className="hui-text-lg fw-bold mb-3 d-flex align-items-center px-3">
          <div className="d-flex me-2">
            <HuiIcon name="reload" size="sm" />
          </div>
          Recurring Payments
        </div>

        {isLoading ? (
          <div className="d-flex align-items-center flex-grow-1 justify-content-center pb-4">
            <HuiLoading size="sm" fixed isOpen />
          </div>
        ) : isEmpty ? (
          createRecurrence
        ) : (
          recurringPayments
        )}
      </HuiPanel>
    </>
  );
};

export default DashboardRecurrences;
