import { Divider } from '@mui/material';
import { HuiCard, HuiCardContent, HuiIcon, HuiInputAmount, HuiSelect } from 'handle-ui';
import { first, isNil, size } from 'lodash';
import { useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SidebarControl from '../../../../components/layout/sidebar/SidebarControl';
import { resetCredits, selectCreditData } from '../../../../redux/CreditDataSlice';
import { saveAppliedCredits } from '../../../../redux/CreditsSlice';
import { applyCreditToInvoice } from '../../../../redux/PaymentDataSlice';
import { selectPaymentReasons } from '../../../../redux/PaymentReasonsSlice';
import { selectPortalInfo } from '../../../../redux/PortalInfoSlice';
import { closeSidebar, selectSidebar, updatePaymentReason, updateTotalToPay } from '../../../../redux/SidebarSlice';
import { checkIfOverpayEnabled, checkIfShortpayEnabled } from '../../../../utils/utilCheckIfPaymentHasError';
import { formatCurrency, getCurrencyPrefix } from '../../../../utils/utilsCurrency';
import { formatNumber } from '../../../../utils/utilsMath';
import { removeSelectedInvoiceClass } from '../../../../utils/utilsTable';
import { getPaymentReasons } from '../payment-form/utilPaymentForm';

const CreditMemoSummary = () => {
  const fieldRef = useRef();
  const dispatch = useDispatch();
  const sidebarInvoice = useSelector(selectSidebar);
  const creditData = useSelector(selectCreditData);
  const portalInfo = useSelector(selectPortalInfo);
  const paymentReasons = useSelector(selectPaymentReasons);

  const { creditApplied = 0, creditOverpay, credits } = creditData;

  const { id, paymentReasonId, paymentType, balance, amount = null, currencyCode } = sidebarInvoice || {};

  const {
    settings: { isOverpayEnabled, isOverpayAdminEnabled, isShortpayEnabled, isShortpayAdminEnabled },
  } = portalInfo;

  const filteredPaymentReasons = getPaymentReasons(paymentReasons, paymentType);
  const _isShortpayEnabled = checkIfShortpayEnabled(isShortpayEnabled, isShortpayAdminEnabled);
  const _isOverpayEnabled = checkIfOverpayEnabled(isOverpayEnabled, isOverpayAdminEnabled);
  let showPaymentReasons = size(filteredPaymentReasons) > 0;

  if (paymentType === 'shortpay') {
    showPaymentReasons = showPaymentReasons && _isShortpayEnabled;
  } else if (paymentType === 'overpay') {
    showPaymentReasons = showPaymentReasons && _isOverpayEnabled;
  }

  const totalPayment = creditApplied + amount;
  if (totalPayment === balance) {
    showPaymentReasons = false;
  }

  const selectedReason = first(filteredPaymentReasons.filter((p) => p.id === paymentReasonId));

  const canEditAmount = _isOverpayEnabled || _isShortpayEnabled;

  const creditAmount = creditApplied || 0;
  const isCreditOverpay = creditOverpay;
  const hasCreditNotUsed = findCreditNotUsed(credits) || 0;
  const maxAmount = formatNumber(balance - creditApplied, 2);
  const maxAmountWithOverPay = _isOverpayEnabled ? undefined : maxAmount;
  const showOverpayBadge = formatNumber(amount + creditApplied, 2) > balance;
  const amountToPay = amount || 0;

  const hasShortPayError = paymentType === 'shortpay' && (isNil(selectedReason?.id) || !_isShortpayEnabled);
  const hasOverPayError = paymentType === 'overpay' && (isNil(selectedReason?.id) || !_isOverpayEnabled);

  const applyCreditDisabled = hasShortPayError || hasOverPayError;

  const currencyPrefix = getCurrencyPrefix(currencyCode);

  const onChangeUpdateAmount = useMemo(
    () => (event) => {
      const value = event.target.value;
      const newAmount = parseFloat(value.replace(/\$|,/g, '')) || 0;
      dispatch(updateTotalToPay(newAmount));
    },
    [dispatch],
  );

  return (
    <>
      <HuiCard className="p-3 mb-3" boxShadow border>
        <HuiCardContent className="m-0 pb-0 px-0">
          <div className="d-flex">
            <div className="flex-grow-1 text-start d-flex align-items-center">
              <h3 className="hui-text fw-bold mb-0">Balance Due: </h3>
            </div>
            <div className="text-end d-flex">
              <div className={`me-2 `}>{formatCurrency(balance, currencyCode)}</div>
            </div>
          </div>

          <Divider className="my-1" />

          <div className="d-flex">
            <div className="flex-grow-1 text-start d-flex align-items-center">
              <h3 className="hui-text fw-bold mb-0">Credit Applied: </h3>
            </div>
            <div className="text-end">
              <HuiInputAmount
                prefix={currencyPrefix}
                className="mb-0 text-end"
                id="balance"
                name="balance"
                value={creditAmount}
                disabled
                allowNegative={false}
              />
            </div>
          </div>

          <Divider className="my-1" />

          <div className="d-flex">
            <div className="flex-grow-1 text-start d-flex align-items-center">
              <h3 className="hui-text-lg fw-bold mb-0">Total to Pay: </h3>
            </div>
            <div className="text-end d-flex align-items-center justify-content-end">
              <div hidden={!showOverpayBadge} className="m-1">
                <span className="rounded-pill hui-bg-green hui-color-white py-0 px-2 hui-text-xs">OVERPAY</span>
              </div>

              <HuiInputAmount
                disabled={!canEditAmount || creditOverpay}
                prefix={currencyPrefix}
                className="mb-0"
                id="amount"
                name="amount"
                placeholder={`${currencyPrefix} Type`}
                value={amountToPay}
                onChange={onChangeUpdateAmount}
                allowNegative={false}
                max={maxAmountWithOverPay}
              />
            </div>
          </div>

          <div className="d-flex bg-light border ps-2 pe-1 py-1 mt-2 rounded-2" hidden={!showPaymentReasons}>
            <div className="flex-grow-1 text-start d-flex align-items-center">
              <h3 className="hui-text fw-bold mb-0">Adjustment reason</h3>
            </div>
            <div className="text-end d-flex">
              <HuiSelect
                error={!selectedReason?.id}
                size="small"
                ref={fieldRef}
                value={selectedReason?.id}
                id={`hui-select-${id}`}
                label="Adjustment reason"
                className="mb-0"
                onChange={handleChangePaymentReason}
                options={filteredPaymentReasons}
              />

              <button className="hui-btn hui-btn-transparent" onClick={cancelPaymentReason}>
                <HuiIcon name="x" size="xs" />
              </button>
            </div>
          </div>

          <div className="hui-bg-green-light border p-2 mt-2 rounded-2" hidden={!isCreditOverpay || !hasCreditNotUsed}>
            Credits applied exceed the invoice balance. You will have a leftover remaining credit of{' '}
            <strong> {formatCurrency(hasCreditNotUsed, currencyCode)} </strong> that will be applied to your account to
            use in the future.
          </div>
        </HuiCardContent>
      </HuiCard>

      <button
        type="button"
        disabled={applyCreditDisabled}
        onClick={applyCredit}
        className="hui-btn hui-btn-lg hui-btn-green w-100"
      >
        Apply Credit
      </button>

      <SidebarControl className="text-center mt-3 hui-btn hui-btn-lg w-100 hui-btn-outline hui-btn-red" button>
        Cancel Apply Credit
      </SidebarControl>
    </>
  );

  function findCreditNotUsed(credits) {
    return (
      credits.reduce((balanceLeft, credit) => {
        return balanceLeft + credit.balanceLeft;
      }, 0) || 0
    );
  }

  function cancelPaymentReason() {
    const { balance } = sidebarInvoice || {};
    const newAmount = balance - creditApplied;
    dispatch(updateTotalToPay(newAmount));
  }

  function handleChangePaymentReason(e) {
    e.stopPropagation();
    const reasonId = e.target.value || undefined;
    handleAddPaymentReason(reasonId);
  }

  function handleAddPaymentReason(reasonId) {
    dispatch(updatePaymentReason(reasonId));
  }

  async function applyCredit() {
    removeSelectedInvoiceClass();
    const invoiceToSave = { ...sidebarInvoice, ...creditData, amount: sidebarInvoice.totalToPay };
    let { paymentReasonId, paymentType, amount, shortPayHasError } = invoiceToSave;
    const maxAmount = balance - creditApplied;
    const amountLeft = formatNumber(maxAmount - amount, 2);
    const isCompletePayment = amountLeft === 0;
    if (isCompletePayment && paymentReasonId) {
      paymentReasonId = undefined;
      shortPayHasError = false;
    }
    await dispatch(applyCreditToInvoice({ ...invoiceToSave, paymentType, paymentReasonId, shortPayHasError }));
    await dispatch(saveAppliedCredits({ newCredits: credits, oldCredits: sidebarInvoice.credits }));
    await dispatch(closeSidebar({}));
    await dispatch(resetCredits({}));
  }
};

export default CreditMemoSummary;
