import React from 'react';
import HeaderAvatar from './HeaderAvatar';
import CurrencySelector from '../../payment/CurrencySelector';

const HeaderProfile = () => {
  return (
    <div className="ms-auto d-flex align-items-center">
      <CurrencySelector />
      <HeaderAvatar />
    </div>
  );
};

export default HeaderProfile;
