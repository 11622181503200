import { addDays } from 'date-fns';
import { HuiCalendar, HuiCheckbox, HuiInput, HuiSelect } from 'handle-ui';
import { isEmpty, size } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import toastr from 'toastr';
import { createOneTimePay } from '../../../../actions/payment/createOneTimePay';
import { createPayment } from '../../../../actions/payment/createPayment';
import { createPrepay } from '../../../../actions/payment/createPrepay';
import { getCardOnFile } from '../../../../actions/payment/getCardOnFile';
import { getPayment } from '../../../../actions/payment/getPayment';
import { getPaymentToken } from '../../../../actions/payment/getPaymentToken';
import BusinessDayMessage from '../../../../components/payment/BusinessDayMessage';
import SecurePaymentMessage from '../../../../components/payment/SecurePaymentMessage';
import SubmitPaymentMessage from '../../../../components/payment/SubmitPaymentMessage';
import { resetCredits } from '../../../../redux/CreditsSlice';
import { resetInvoicesPayment } from '../../../../redux/InvoicesPaymentSlice';
import { resetInvoices } from '../../../../redux/InvoicesSlice';
import { forceCloseLoading, openLoading } from '../../../../redux/LoadingSlice';
import { closeModal, openModal } from '../../../../redux/ModalSlice';
import { resetPaymentData, selectPaymentData } from '../../../../redux/PaymentDataSlice';
import { selectPaymentReasons } from '../../../../redux/PaymentReasonsSlice';
import { updatePayment } from '../../../../redux/PaymentSlice';
import { selectPortalInfo } from '../../../../redux/PortalInfoSlice';
import { store } from '../../../../store/store';
import UtilPaymentMethods from '../../../../utils/utilPaymentMethods';
import { validateAccountNumber, validateRoutingNumber } from '../../../../utils/utilsACH';
import { accountHolderTypeOptions, accountTypeOptions } from '../../../../utils/utilsACHOptions';
import { isBusinessDay } from '../../../../utils/utilsDate';
import { getCustomerName } from '../../../../utils/utilsOneTimePayment';
import { paymentOriginOptions } from '../../../../utils/utilsPaymentOriginOptions';
import HuiPaymentSummary from '../confirm-payment/HuiPaymentSummary';
import OneClickPay from './OneClickPay';
import {
  checkPaymentFormForError,
  generateIdempotencyKey,
  hasPaymentReasons,
  prepareInvoicesToPay,
} from './utilPaymentForm';

const HuiPaymentAch = (props) => {
  const { cardOnFileSearchDisabled = false, hidden, small } = props;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isSlim = pathname.endsWith('-slim');
  const isWithinIframe = window.parent !== window;
  const isRequestWaiver = !!searchParams.get('requestWaiver');
  const portalInfo = useSelector(selectPortalInfo);
  const paymentData = useSelector(selectPaymentData);
  const paymentReasons = useSelector(selectPaymentReasons);

  const { isPrepaid, isOneTimePay } = paymentData || {};

  const {
    settings: { isCardOnFileEnabled = false, isScheduledPaymentEnabled = false, achProvider = 'CARDPOINTE' } = {},
  } = portalInfo || {};

  const payment = {
    invoices: paymentData.paymentInvoices,
    subtotal: paymentData.subtotal,
    hasOverpayReasons: hasPaymentReasons(paymentReasons, 'overpay'),
    hasShortpayReasons: hasPaymentReasons(paymentReasons, 'shortpay'),
    paymentMethod: UtilPaymentMethods.ACH,
    info: paymentData.info,
    isOneTimePay: paymentData.isOneTimePay,
    paymentRequestToken: paymentData.paymentRequestToken,
  };

  const [fields, setFields] = useState({
    accountNumber: '',
    routingNumber: '',
    holderName: '',
    accountType: '',
    accountHolderType: '',
    origin: '',
    savePaymentToken: false,
    cardOnFile: null,
    scheduledAt: null,
    scheduled: false,
    recurring: false,
  });
  const [cardOnFileList, setCardOnFileList] = useState({});
  const [scheduledCalendar, setScheduledCalendar] = useState({
    dateSelected: null,
    value: false,
  });
  const [expanded, setExpanded] = useState(true);
  const [idempotencyKey] = useState(generateIdempotencyKey());

  const isScheduled = fields.scheduled && !isOneTimePay;

  const isABusinessDay = isBusinessDay(paymentData?.scheduledAt ?? scheduledCalendar?.dateSelected ?? new Date());

  const sizeClass = small ? 'small' : 'large';

  const formSubmitHandler = async (e) => {
    e.preventDefault();
    if (hasPaymentError()) {
      toastr.warning(`Please review the invoices to proceed.`);
      return;
    }
    store.dispatch(openLoading());

    try {
      if (!isEmpty(fields.cardOnFile) || validatePayment(fields.accountNumber, fields.routingNumber)) {
        await preparePayment(
          fields.accountNumber,
          fields.routingNumber,
          fields.accountType,
          fields.accountHolderType,
          fields.holderName,
          fields.origin,
          fields.cardOnFile,
        );
      }
    } catch (e) {
      toastr.error('Error processing payment, check your payment data');
      console.error('Error processing payment', e);
    }
    store.dispatch(forceCloseLoading());
  };

  async function preparePayment(
    accountNumber,
    routingNumber,
    accountType,
    accountHolderType,
    holderName,
    origin,
    cardOnFile,
  ) {
    const { token: _token } = props;
    const { checkoutToken } = await store.getState().invoicesPayment;
    const paymentToken = achProvider === 'HANDLE' ? achProvider : _token || checkoutToken;
    const token = !cardOnFile ? await getPaymentToken(paymentToken, accountNumber, routingNumber) : null;
    const payload = await createPaymentPayload(token, accountType, accountHolderType, holderName, origin);
    await processPayment(payload);
  }

  function validatePayment(accountNumber, routingNumber) {
    let message;

    if (!validateAccountNumber(accountNumber)) {
      message = 'Invalid account number, please review your account information';
    } else if (!validateRoutingNumber(routingNumber)) {
      message = 'Invalid routing number, please review your account information';
    }

    if (message) {
      store.dispatch(forceCloseLoading({}));

      const modal = {
        type: 'alerts',
        title: 'Error processing payment',
        message: message,
        confirmLabel: 'Ok, I Will Fix It',
        confirmAction: () => store.dispatch(closeModal()),
        showConfirmButton: true,
      };

      store.dispatch(openModal(modal));
      return false;
    }

    return true;
  }

  async function createPaymentPayload(token, accountType, accountHolderType, holderName, origin) {
    const paymentData = await store.getState().paymentData;

    const {
      subtotal,
      isPrepaid,
      credit,
      balance,
      isOneTimePay,
      info: paymentDataInfo,
      paymentRequestToken,
      currencyCode = 'USD',
    } = paymentData;

    if (isOneTimePay) {
      const info = {
        ...(paymentDataInfo || {}),
        name: getCustomerName(paymentDataInfo),
        notes: fields.notes,
      };
      return {
        paymentType: UtilPaymentMethods.ACH,
        subtotal: parseFloat(paymentData.subtotal),
        paymentRequestToken: paymentRequestToken || undefined,
        currencyCode,
        invoices: [],
        info: isEmpty(info) ? undefined : info,
        recurrence: {},
        isPrepaid,
        token,
        accountType,
        accountHolderType,
        holderName,
        origin: isEmpty(origin) ? null : origin,
        isOneTimePay: true,
      };
    }

    const total = subtotal - credit - balance;

    const shouldAddRecurrence = isPrepaid && paymentData?.recurrence?.frequency === 'MONTHLY';

    const paymentJson = {
      paymentType: UtilPaymentMethods.ACH,
      subtotal: parseFloat(subtotal),
      balance: parseFloat(balance),
      credit: parseFloat(credit),
      total: total,
      currencyCode,
      invoices: !isPrepaid ? prepareInvoicesToPay(paymentData.paymentInvoices) : [],
      info: isPrepaid ? paymentData.info : {},
      recurrence: shouldAddRecurrence ? paymentData.recurrence : {},
      isPrepaid,
      token,
      accountType,
      accountHolderType,
      holderName,
      savePaymentToken: fields.savePaymentToken,
      scheduledAt: isPrepaid ? paymentData.scheduledAt : fields.scheduledAt,
      origin: isEmpty(origin) ? null : origin,
    };

    if (fields.cardOnFile) {
      const selectedCardOnFile = cardOnFileList?.rows.find((c) => c.id === parseInt(fields.cardOnFile));
      paymentJson.accountType = selectedCardOnFile?.accountType;
      paymentJson.accountHolderType = selectedCardOnFile?.accountHolderType;
      paymentJson.cardOnFile = Number.parseInt(fields.cardOnFile);
    }
    return paymentJson;
  }

  async function processPayment(payment) {
    try {
      const submitPayment = await handleCreatePayment(payment);
      await paymentSuccess(submitPayment);
    } catch (e) {
      let modal = {};
      store.dispatch(forceCloseLoading({}));
      if (e?.error?.code === 'amount_over_maximum') {
        modal = {
          type: 'amountOverMaximum',
          size: 'md',
          confirmLabel: 'Ok, I Will Fix It',
          confirmAction: () => store.dispatch(closeModal()),
          showConfirmButton: true,
          hideClose: true,
        };
      } else {
        modal = {
          type: 'alerts',
          title: 'Error processing payment',
          message: e.message,
          confirmLabel: 'Ok, I Will Fix It',
          confirmAction: () => store.dispatch(closeModal()),
          showConfirmButton: true,
        };
      }
      store.dispatch(openModal(modal));
    }
  }

  async function handleCreatePayment(payment) {
    const { isPrepaid, isOneTimePay } = payment;

    if (isPrepaid) {
      return createPrepay(payment, idempotencyKey);
    }

    if (isOneTimePay) {
      return createOneTimePay(payment, idempotencyKey);
    }

    return createPayment(payment, idempotencyKey);
  }

  async function paymentSuccess(payment) {
    try {
      if (isOneTimePay) {
        return await redirectToConfirmationPage(payment);
      }
      const { code } = payment;
      const result = await getPayment(code);
      await store.dispatch(updatePayment(result));
      await store.dispatch(forceCloseLoading({}));
      await redirectToThanksPage(code);
    } catch (e) {
      toastr.error('Error processing payment');
    }
  }

  async function redirectToConfirmationPage(payment) {
    let path = '/checkout-confirmation';
    navigate(path, {
      state: {
        payment,
      },
    });
    await resetPayment();
  }

  async function redirectToThanksPage(code) {
    let path = isSlim ? `/thanks-slim/${code}` : `/thanks/${code}`;
    if (isRequestWaiver) {
      path += `?requestWaiver=true`;
    }
    navigate(path);
    await resetPayment();
  }

  async function resetPayment() {
    await store.dispatch(resetPaymentData({}));
    await store.dispatch(resetInvoices({}));
    await store.dispatch(resetInvoicesPayment({}));
    await store.dispatch(resetCredits({}));
  }

  useEffect(() => {
    async function loadCardOnFile() {
      const cardOnFileList = await getCardOnFile(UtilPaymentMethods.ACH);
      setCardOnFileList(cardOnFileList);
    }

    if (!size(cardOnFileList) && !cardOnFileSearchDisabled) {
      loadCardOnFile();
    }
  }, [cardOnFileList, setCardOnFileList, cardOnFileSearchDisabled]);

  const showCardOnFileList = isCardOnFileEnabled && cardOnFileList?.count > 0;

  const showCards = showCardOnFileList && fields.cardOnFile !== '';

  function oneClickPayChange(e) {
    const { expanded: expandedChange, cardOnFile, cvv } = e;
    setExpanded(expandedChange);
    setFields({ ...fields, cvv, cardOnFile });
  }

  return (
    <form onSubmit={formSubmitHandler} method="post" id="payment-form" hidden={hidden}>
      <OneClickPay type={UtilPaymentMethods.ACH} cardOnFileList={cardOnFileList || {}} onChange={oneClickPayChange} />

      {!showCards && (
        <>
          <div className="hui-text-lg fw-bold mb-4 mt-4">Bank Account Info</div>
          <div className="row">
            <div className="col-12">
              <HuiInput
                label="Account holder's name"
                id="holderName"
                name="holderName"
                value={fields.holderName}
                onChange={handleChange}
                topLabel
                required
                size={sizeClass}
                dataPrivate
              />
            </div>

            <div className="col-12">
              <HuiInput
                label="Account Number"
                id="accountNumber"
                name="accountNumber"
                value={fields.accountNumber}
                onChange={handleChange}
                topLabel
                required
                size={sizeClass}
                dataPrivate
              />
            </div>

            <div className="col-12">
              <HuiInput
                label="Routing Number"
                id="routingNumber"
                name="routingNumber"
                type="text"
                value={fields.routingNumber}
                onChange={handleChange}
                size={sizeClass}
                topLabel
                required
                dataPrivate
              />
            </div>

            <div className="col-12">
              <HuiSelect
                topLabel
                required
                size={sizeClass}
                value={fields.accountType}
                id="accountType"
                name="accountType"
                label="Account Type"
                onChange={handleSelect}
                options={accountTypeOptions}
                dataPrivate
              />
            </div>

            <div className="col-12">
              <HuiSelect
                topLabel
                required
                size={sizeClass}
                value={fields.accountHolderType}
                id="accountHolderType"
                name="accountHolderType"
                label="Account Holder Type"
                onChange={handleSelect}
                options={accountHolderTypeOptions}
                dataPrivate
              />
            </div>
          </div>
          <div
            className="col-12 mb-3 border p-2 rounded hui-bg-gray-light hui-border-light"
            hidden={!isCardOnFileEnabled}
          >
            <HuiCheckbox
              className="m-0"
              name="savePaymentToken"
              onChange={handleChange}
              size="small"
              label="Store payment data for future use"
              showLabel
              checked={fields.savePaymentToken}
            />
          </div>
        </>
      )}

      <div hidden={!isWithinIframe}>
        <div className="row">
          <div className="col-12">
            <HuiSelect
              topLabel
              required={isWithinIframe}
              size={sizeClass}
              value={fields.origin}
              id="origin"
              name="origin"
              label="Request Method"
              onChange={handleSelect}
              options={paymentOriginOptions}
            />
          </div>
        </div>
      </div>

      <div
        className="col-12 border p-2 rounded hui-bg-gray-light hui-border-light mt-4"
        hidden={isPrepaid || !(isScheduledPaymentEnabled && isCardOnFileEnabled)}
      >
        <HuiCheckbox
          className="m-0"
          name="scheduled"
          onChange={handleChange}
          size="small"
          label="Schedule payment (Optional)"
          showLabel
          checked={fields.scheduled}
        />

        <div hidden={!isScheduled}>
          <h2 className="hui-text fw-bold my-3">Please select a date to process the payment.</h2>
          <HuiCalendar
            onChange={(item) => {
              setScheduledCalendar({ dateSelected: item, value: true });
              setFields({ ...fields, scheduledAt: item.toISOString() });
            }}
            onClear={() => {
              setScheduledCalendar({ dateSelected: null, value: false });
              setFields({ ...fields, scheduledAt: null });
            }}
            handleOnClick={() => {}}
            onClickDisabled={true}
            className="mb-0 hui-calendar"
            size="small"
            label="Scheduled Date"
            id="scheduledAt"
            name="scheduledAt"
            minDate={addDays(new Date(), 1)}
            date={scheduledCalendar}
          />
          <h2 className="hui-text-sm mt-3 mb-0">
            You can review or cancel this transaction on the payments report page.
          </h2>
        </div>
      </div>

      <div className="col-12 mt-2" hidden={!isOneTimePay}>
        <HuiInput
          multiline
          maxRows={3}
          minRows={3}
          label="Notes"
          id="notes"
          name="notes"
          type="text"
          value={fields.notes}
          onChange={handleChange}
          size={sizeClass}
          required={isOneTimePay}
          topLabel
          placeholder="Please provide information for this payment "
        />
        <HuiPaymentSummary hideTitle />
      </div>

      <BusinessDayMessage isBusinessDay={isABusinessDay} />

      <div className="button-container mt-4">
        <button
          className="hui-btn hui-btn-green hui-btn-xl w-100"
          type="submit"
          hidden={isScheduled}
          disabled={hasPaymentError()}
        >
          Submit Payment
        </button>
        <button
          type="submit"
          className="hui-btn hui-btn-yellow hui-btn-xl w-100"
          hidden={!isScheduled}
          disabled={hasPaymentError()}
        >
          Schedule Payment Now
        </button>
        <SecurePaymentMessage />
        <SubmitPaymentMessage type="ACH" isPaidOnBehalf={isWithinIframe} />
      </div>
    </form>
  );

  function handleChange(e) {
    const { name, value, checked, type } = e.target || {};
    let newFields = {};
    if (name === 'scheduled') {
      setScheduledCalendar({ dateSelected: null, value: false });
      newFields = { scheduledAt: null };
    }

    setFields({ ...fields, ...newFields, [name]: type === 'checkbox' ? checked : value });
  }

  async function handleSelect(e) {
    e.stopPropagation();
    setFields({ ...fields, [e.target.name]: e.target.value });
  }

  function hasPaymentError() {
    return checkPaymentFormForError(fields, payment, portalInfo.settings);
  }
};

export default HuiPaymentAch;
