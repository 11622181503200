export const states = [
  {
    title: 'Alberta',
    id: 'AB'
  },
  {
    title: 'British Columbia',
    id: 'BC'
  },
  {
    title: 'Manitoba',
    id: 'MB'
  },
  {
    title: 'New Brunswick',
    id: 'NB'
  },
  {
    title: 'Newfoundland and Labrador',
    id: 'NL'
  },
  {
    title: 'Nova Scotia',
    id: 'NS'
  },
  {
    title: 'Ontario',
    id: 'ON'
  },
  {
    title: 'Prince Edward Island',
    id: 'PE'
  },
  {
    title: 'Quebec',
    id: 'QC'
  },
  {
    title: 'Saskatchewan',
    id: 'SK'
  },
  {
    title: 'Northwest Territories',
    id: 'NT'
  },
  {
    title: 'Nunavut',
    id: 'NU'
  },
  {
    title: 'Yukon',
    id: 'YT'
  }
];