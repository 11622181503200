import { axiosInstance } from '../../utils/utilsAxios';

export async function getUnpaidInvoicesPublic(customerToken) {
  const url = `v1/invoices/unpaid/public?customerToken=${customerToken}&type=INVOICE`;

  console.log('fetching: ', url);

  try {
    const response = await axiosInstance.get(url);
    const invoices = { ...response.data || {}, isLoading: false };
    return invoices;
  } catch (e) {
    throw new Error(e.message);
  }
}
