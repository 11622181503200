import { createSlice } from '@reduxjs/toolkit';
import { first } from 'lodash';

const initialState = {
  value: {
    currencyCode: null,
    currencyCodeList: [],
  },
};

export const currencyCodeSlice = createSlice({
  name: 'currencyCode',
  initialState,
  reducers: {
    // Action to set the selected currency code
    updateCurrencyCode: (state, action) => {
      state.value.currencyCode = action.payload;
    },
    // Action to set the list of available currency codes
    updateCurrencyCodeList: (state, action) => {
      const codeList = action.payload;
      state.value.currencyCodeList = codeList;
      state.value.currencyCode = first(codeList) ?? 'USD';
    },
  },
});

export const { updateCurrencyCode, updateCurrencyCodeList } = currencyCodeSlice.actions;

export const selectCurrencyCode = (state) => state.currencyCode.value;

// Export the reducer to be added to the store
export default currencyCodeSlice.reducer;
