import { parse } from 'content-disposition-header';
import { saveAs } from 'file-saver';
import { startCase, toLower, toUpper } from 'lodash';
import moment from 'moment/moment';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import toastr from 'toastr';
import { downloadPaymentsCsv } from '../../../actions/payment/downloadPaymentsCsv';
import { getPaymentsHistory } from '../../../actions/payment/getPaymentsHistory';
import HuiTable from '../../../components/table/HuiTable';
import HuiTableCell from '../../../components/table/HuiTableCell';
import HuiTablePaidInvoices from '../../../components/table/HuiTablePaidInvoices';
import HuiTablePaymentMethod from '../../../components/table/HuiTablePaymentMethod';
import HuiTableStatus from '../../../components/table/HuiTableStatus';
import HuiTableTotal from '../../../components/table/HuiTableTotal';
import HuiTableFooter from '../../../components/table/components/HuiTableFooter';
import HuiTableHeader from '../../../components/table/components/HuiTableHeader';
import { selectCurrencyCode } from '../../../redux/CurrencyCodeSlice';
import { forceCloseLoading } from '../../../redux/LoadingSlice';
import { resetPayment } from '../../../redux/PaymentSlice';
import { formatCurrency } from '../../../utils/utilsCurrency';
import {
  datePickerWithRangeFilter,
  downloadCsvButtonFilter,
  invoiceFilter,
  jobAddressFilter,
  jobNameFilter,
  paymentStatusFilter,
  paymentTypeFilter,
  poNumberFilter,
  submitterEmailFilter,
  submitterNameFilter,
} from '../../../utils/utilsFilters';

let columns = [
  {
    field: 'status',
    label: 'Status',
    width: '105px',
    cellRenderer: (props) => <HuiTableStatus {...props} />,
  },
  {
    field: 'createdAt',
    label: 'Originated',
    width: '95px',
    getValue: ({ value }) => (value !== null && moment(value).format('M/DD/YYYY')) || '',
    cellRenderer: (props) => <HuiTableCell {...props} />,
  },
  {
    field: 'scheduledAt',
    label: 'Scheduled',
    width: '95px',
    getValue: ({ value }) => (value !== null && moment(value).format('M/DD/YYYY')) || '',
    cellRenderer: (props) => <HuiTableCell {...props} />,
  },
  {
    field: 'code',
    label: 'Confirmation Code',
    width: 'auto',
    cellRenderer: (props) => <HuiTableCell {...props} />,
  },
  {
    field: 'paymentType',
    label: 'Type',
    width: 'auto',
    getValue: ({ value }) => toUpper(startCase(toLower(value))),
    cellRenderer: (props) => <HuiTablePaymentMethod {...props} />,
  },
  {
    field: 'createdBy',
    label: 'Paid By',
    width: 'auto',
    getValue: ({ value }) => value.name,
    cellRenderer: (props) => <HuiTableCell {...props} />,
  },
  {
    field: 'paymentInvoicesCount',
    label: 'Payment Details',
    width: '147px',
    getValue: ({ value }) => `${value} Invoice${value.value > 1 ? 's' : ''}`,
    cellRenderer: (props) => <HuiTablePaidInvoices {...props} />,
  },
  {
    field: 'total',
    label: 'Payment Total',
    pinned: true,
    width: '150px',
    className: 'text-end',
    headerCellRenderer: ({ column }) => <div className="d-block w-100 text-end">{column.label}</div>,
    getValue: ({ value, rowData }) => formatCurrency(value, rowData.currencyCode),
    cellRenderer: (props) => <HuiTableTotal {...props} />,
  },
];

columns = columns.map((column, i) => {
  return { ...column, id: i };
});

const HistoryPayments = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currencyCode } = useSelector(selectCurrencyCode);

  const [searchParams] = useSearchParams();

  searchParams.set('currencyCode', currencyCode);

  const rowsRef = useRef([]);

  const [allRows, setAllRows] = useState(rowsRef.current);
  const [totalRows, setTotalRows] = useState(null);
  const [tableManager, setTableManager] = useState({});

  const paymentsHistoryFilters = {
    filters: [
      {
        ...downloadCsvButtonFilter,
        onClick: () => handleDownload(),
      },
      {
        name: 'search',
        type: 'search',
        label: 'Search by Confirmation Code, Invoice or Job Id',
      },
      datePickerWithRangeFilter,
    ],
    advancedFilters: [
      invoiceFilter,
      poNumberFilter,
      jobNameFilter,
      jobAddressFilter,
      paymentStatusFilter,
      paymentTypeFilter,
      submitterNameFilter,
      submitterEmailFilter,
    ],
  };

  const onRowsReset = () => {
    rowsRef.current = [];
    setAllRows(rowsRef.current);
    setTotalRows(null);
  };

  const onLoadTableManager = (tableManager) => {
    setTableManager(tableManager);
  };

  useEffect(() => {
    async function findPayments() {
      if (!searchParams.get('size')) {
        searchParams.set('size', tableManager.paginationApi.pageSize);
      }

      const params = Object.fromEntries(searchParams);
      params.hideScheduledRecurrence = true;

      const result = await getPaymentsHistory(params);

      rowsRef.current = tableManager.asyncApi.mergeRowsAt(
        rowsRef.current,
        result.rows,
        (searchParams.get('page') - 1) * tableManager.paginationApi.pageSize,
      );
      setAllRows(rowsRef.current);
      setTotalRows(result.count);
    }
    if (tableManager.asyncApi && currencyCode) {
      findPayments();
    }
  }, [dispatch, searchParams, currencyCode, tableManager]);

  const isLoading = totalRows == null;

  return (
    <HuiTable
      additionalProps={{
        header: {
          showTableCountInfo: true,
          availableFilters: paymentsHistoryFilters,
        },
      }}
      onRowClick={({ data }) => redirectToPayment(data.code)}
      components={{ Header: HuiTableHeader, Footer: HuiTableFooter }}
      className="hui-table"
      isLoading={isLoading}
      isPaginated={true}
      columns={columns}
      rows={allRows}
      totalRows={totalRows}
      contentName="Payments"
      onLoad={onLoadTableManager}
      onRowsRequest={() => {}}
      onRowsReset={onRowsReset}
    />
  );

  async function handleDownload() {
    try {
      let download = await downloadPaymentsCsv(searchParams);
      const headers = download.headers;
      const data = download.data;

      const blob = new Blob([data], { type: 'text/csv' });
      const disposition = parse(headers['content-disposition']);
      const fileName = disposition.parameters.filename;
      saveAs(blob, fileName);
      toastr.success(`Payment CSV file downloaded successfully`);
    } catch (e) {
      await dispatch(forceCloseLoading({}));
      toastr.error(`Error downloading Payment CSV file`);
      console.error(`Error downloading Payment CSV file`, e);
    }
  }

  async function redirectToPayment(code) {
    await dispatch(resetPayment({}));
    navigate(code);
  }
};

export default HistoryPayments;
