import React from 'react';
// import './HuiBtn.scss';

/**
 * @namespace HuiBtn
 * hui-btn Params
 *
 * @param {event} button
 *
 * @author [Lucas Azevedo](https://github.com/lucasazevedonet)
 */

const HuiBtn = () => {
  return <div>Btnsssssss</div>;
};

export default HuiBtn;
