import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, selectModal } from '../../../redux/ModalSlice';
import ModalAlerts from './ModalAlerts';
import ModalSendEmail from './ModalSendEmail';
import ModalSurcharge from './ModalSurcharge';
import Draggable from 'react-draggable';
import { Modal as MaterialModal, Tooltip } from '@mui/material';
import { HuiModal } from 'handle-ui';
import { isNil } from 'lodash';
import ModalDebitCard from './ModalDebitCard';
import ModalConfirmEmail from './ModalConfirmEmail';
import ModalAmountOverMaximum from './ModalAmountOverMaximum';
import ModalCurrencySelector from './ModalCurrencySelector';

const Modal = (props) => {
  const dispatch = useDispatch();
  const appModal = useSelector(selectModal);
  const modalRef = useRef(null);

  const getFromAppModal = isNil(props?.isOpen);
  const { isOpen = false, keepOpen, type, hideClose, draggable, children, size } = getFromAppModal ? appModal : props;

  const getModalType = {
    currencySelector: <ModalCurrencySelector modal={appModal} />,
    alerts: <ModalAlerts modal={appModal} />,
    creditCardSurcharge: <ModalSurcharge modal={appModal} />,
    debitCard: <ModalDebitCard modal={appModal} />,
    confirmEmail: <ModalConfirmEmail modal={appModal} />,
    sendEmail: <ModalSendEmail modal={appModal} close={close} />,
    amountOverMaximum: <ModalAmountOverMaximum modal={appModal} />,
  };

  const modalContent = getModalType[type] || children;

  const modalClass = size ? `hui-modal hui-modal-${size}` : '';
  return (
    <MaterialModal open={isOpen} onClose={!keepOpen ? () => close() : null}>
      <div className={modalClass}>
        {!hideClose && (
          <Tooltip arrow placement="bottom" title="Close">
            <button
              onClick={close}
              type="button"
              className="hui-modal-close hui-btn hui-btn-transparent"
              aria-label="Close"
            >
              <i className="d-block hui-svg-x"></i>
            </button>
          </Tooltip>
        )}
        {!!draggable ? (
          <Draggable
            positionOffset={{ x: '-50%', y: '-50%' }}
            defaultPosition={{ x: 0, y: 0 }}
            scale={1}
            modalRef={modalRef}
            handle=".hui-modal-move"
            enableUserSelectHack={true}
            defaultClassName="hui-modal-draggable"
          >
            <div>
              <HuiModal modal={appModal} hideClose={hideClose} close={close} type={type}>
                {modalContent}
              </HuiModal>
            </div>
          </Draggable>
        ) : (
          <HuiModal modal={appModal} hideClose={hideClose} close={close} type={type}>
            {modalContent}
          </HuiModal>
        )}
      </div>
    </MaterialModal>
  );

  async function close() {
    await dispatch(closeModal());
  }
};

export default Modal;
