import { Divider } from '@mui/material';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectSidebar } from '../../../redux/SidebarSlice';
import { formatCurrency } from '../../../utils/utilsCurrency';
import { invoicePaidPercentage } from '../../../utils/utilsTexts';

const SidebarInvoiceBalance = () => {
  const creditInvoice = useSelector(selectSidebar);

  const { currentBalance = 0, totalAmount = 0, currencyCode } = creditInvoice;

  return (
    <>
      <div className="d-flex">
        <div className="flex-grow-1 text-start">
          <h3 className="hui-text fw-bold mb-0">Balance: </h3>
        </div>
        <div className="text-end">{formatCurrency(currentBalance, currencyCode)}</div>
      </div>

      <Divider className="my-1" />

      <div className="d-flex">
        <div className="flex-grow-1 text-start">
          <h3 className="hui-text fw-bold mb-0">Total: </h3>
        </div>
        <div className="text-end">{formatCurrency(totalAmount, currencyCode)}</div>
      </div>

      <Divider className="my-1" />

      <div className="d-flex">
        <div className="flex-grow-1 text-end">{invoicePaidPercentage(totalAmount, currentBalance, ' % paid')}</div>
      </div>
    </>
  );
};

export default SidebarInvoiceBalance;
