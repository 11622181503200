import { createSlice } from '@reduxjs/toolkit';
import { compact, flatMap, get, map, size, sum, uniqBy } from 'lodash';
import update from 'react-addons-update';
import { getAvailableCredits } from '../utils/utilsCreditsInvoice';
import { formatNumber } from '../utils/utilsMath';

const initialState = {
  invoicesBalance: 0,
  openAccountTotal: null,
  isPrepaid: false,
  subtotal: 0,
  balance: 0,
  credit: 0,
  total: 0,
  prepayTotal: 0,
  info: {},
  recurrence: {},
  paymentInvoices: [],
  usedCreditIds: [],
  scheduledAt: null,
  isOneTimePay: false,
  currencyCode: 'USD',
  paymentRequestToken: null,
};

export const paymentDataSlice = createSlice({
  name: 'paymentData',
  initialState,
  reducers: {
    addPrepay: (state, action) => {
      const { prepayTotal, info, recurrence, scheduledAt } = action.payload;
      state.info = info;
      state.recurrence = recurrence;
      state.prepayTotal = prepayTotal;
      state.isPrepaid = true;
      state.scheduledAt = scheduledAt;
    },

    updatePrepay: (state, action) => {
      const { isPrepaid } = action.payload;
      state.isPrepaid = !!isPrepaid;
    },

    resetPrepay: (state, action) => {
      state.info = {};
      state.recurrence = {};
      state.prepayTotal = 0;
      state.isPrepaid = false;
      state.scheduledAt = null;
    },

    addInvoice: (state, action) => {
      const { paymentInvoices } = state;
      const { data, allCredits } = action.payload;
      const { balance } = data;

      const availableCreditIds = getAvailableCredits(data, allCredits);

      const newInvoice = {
        ...data,
        amount: balance,
        paymentType: 'complete',
        added: true,
        availableCreditIds,
      };
      const newState = update(paymentInvoices, { $push: [newInvoice] });

      state.subtotal += newInvoice.amount;
      state.paymentInvoices = [...newState];
    },

    addInvoices: (state, action) => {
      const { paymentInvoices } = state;
      const { invoices, allCredits } = action.payload;

      const _invoices = map(invoices, (data) => {
        const { balance } = data;
        const availableCreditIds = getAvailableCredits(data, allCredits);
        state.subtotal += balance;
        return {
          ...data,
          amount: balance,
          paymentType: 'complete',
          added: true,
          availableCreditIds,
        };
      });
      const newState = update(paymentInvoices, { $push: _invoices });
      state.paymentInvoices = [...newState];
    },

    removeCreditFromSubtotal: (state, action) => {
      const { creditApplied } = action.payload;
      state.subtotal -= creditApplied;
    },

    applyCreditToInvoice: (state, action) => {
      const { id, amount, credits } = action.payload;

      const newInvoice = state?.paymentInvoices?.map((i) =>
        i.id === id ? { ...i, ...action.payload, amount, credits } : i,
      );

      state.paymentInvoices = [...newInvoice];

      paymentDataSlice.caseReducers.updateSubtotalPayment(state, action);
    },

    updateSubtotalPayment: (state, action) => {
      const { paymentInvoices } = state;

      const total =
        paymentInvoices?.reduce((amount, invoice) => {
          const credit =
            invoice?.credits?.reduce((creditAmount, credit) => {
              return creditAmount + credit.amount;
            }, 0) || 0;

          const finalAmount = invoice.amount || 0;
          let finalSubtotal;

          if (credit >= finalAmount) {
            finalSubtotal = finalAmount - credit;
          } else if (finalAmount > credit) {
            finalSubtotal = finalAmount + credit;
          }

          return amount + Math.abs(finalSubtotal);
        }, 0) || 0;

      state.subtotal = formatNumber(total, 2);
    },

    changeInvoiceTotal: (state, action) => {
      const { paymentInvoices, id, newAmount, paymentTypeError, paymentType } = action.payload;

      const invoice = paymentInvoices.find((i) => i.id === id);

      const oldAmount = get(invoice, 'amount');

      state.subtotal -= oldAmount;
      state.subtotal += newAmount;

      const hasInvoiceError = paymentTypeError || newAmount < 1;

      const newInvoice = state?.paymentInvoices?.map((i) =>
        i.id === id ? { ...i, amount: newAmount, hasInvoiceError, paymentType } : i,
      );

      state.paymentInvoices = [...newInvoice];
    },

    addPaymentReason: (state, action) => {
      const { id, paymentReasonId } = action.payload;
      const newReason = state?.paymentInvoices?.map((i) => (i.id === id ? { ...i, paymentReasonId } : i));

      state.paymentInvoices = [...newReason];
    },

    removePaymentReason: (state, action) => {
      const { id, paymentType } = action.payload;
      const newReason = state?.paymentInvoices?.map((i) =>
        i.id === id ? { ...i, paymentReasonId: undefined, paymentType: paymentType || i.paymentType } : i,
      );

      state.paymentInvoices = [...newReason];
    },

    updateOpenAccountTotal: (state, action) => {
      const openAccountTotal = action.payload;

      state.openAccountTotal = formatNumber(openAccountTotal, 2);
    },

    updateBalance: (state, action) => {
      const paymentInvoices = action.payload;
      const balanceAmount =
        paymentInvoices?.rows?.reduce((balance, invoice) => {
          return balance + invoice.balance;
        }, 0) || 0;

      state.invoicesBalance = formatNumber(balanceAmount, 2);
    },

    removeInvoice: (state, action) => {
      const { id, amount } = action.payload;

      const invoiceIndex = state.paymentInvoices.findIndex((i) => i.id === id);
      state.paymentInvoices.splice(invoiceIndex, 1);
      state.subtotal -= amount;
    },

    removeCredit: (state, action) => {
      console.log(action.payload);
    },

    resetPaymentData: (state, action) => {
      // state.invoicesBalance = 0;
      state.isPrepaid = false;
      state.subtotal = 0;
      state.balance = 0;
      state.credit = 0;
      state.total = 0;
      state.info = {};
      state.recurrence = {};
      state.paymentInvoices = [];
      state.isOneTimePay = false;
      state.currencyCode = 'USD';
      state.paymentRequestToken = null;
      state.openAccountTotal = null;
    },

    updateCurrencyCode: (state, action) => {
      state.currencyCode = action.payload;
    },

    updateCreditsToInvoice: (state, action) => {
      const { id, credits } = action.payload;

      const newInvoice = state?.paymentInvoices?.map((i) => (i.id === id ? { ...i, credits } : i));

      state.paymentInvoices = [...newInvoice];

      paymentDataSlice.caseReducers.updateSubtotalPayment(state, action);
    },

    updatePaymentDataInfo: (state, action) => {
      const { info } = action.payload;
      state.info = info;
    },

    updatePaymentRequest: (state, action) => {
      const { subtotal, currencyCode, paymentRequestToken } = action.payload;
      state.subtotal = subtotal;
      state.total = subtotal;
      state.currencyCode = currencyCode;
      state.paymentRequestToken = paymentRequestToken;
      state.isOneTimePay = true;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) => action.type.startsWith('paymentData/'),
      (state, action) => {
        //TODO fix credit and remove all subtotal and total
        const usedCredits = compact(uniqBy(flatMap(state.paymentInvoices, 'credits'), 'id'));
        state.usedCreditIds = map(usedCredits, 'id');
        state.credit = sum(map(usedCredits, 'creditApplied'));
        updatePaymentTotal(state, action);
      },
    );
  },
});

function updatePaymentTotal(state, action) {
  const { paymentInvoices, isOneTimePay } = state;
  if (isOneTimePay && !size(paymentInvoices)) {
    return state;
  }

  let creditTotal = 0;
  const subTotal =
    paymentInvoices?.reduce((subTotal, invoice) => {
      const credit =
        invoice?.credits?.reduce((creditAmount, credit) => {
          return creditAmount + credit.amount;
        }, 0) || 0;

      creditTotal += credit;

      return subTotal + (invoice.amount + credit);
    }, 0) || 0;

  state.subtotal = formatNumber(subTotal + state.prepayTotal, 2);
  state.credit = formatNumber(creditTotal, 2);
  state.total = formatNumber(state.subtotal - state.credit, 2);
}

export const {
  addPrepay,
  updatePrepay,
  addInvoice,
  applyCreditToInvoice,
  changeInvoiceTotal,
  addPaymentReason,
  updateBalance,
  updateOpenAccountTotal,
  resetPaymentData,
  removeInvoice,
  removeCreditFromSubtotal,
  updateCreditsToInvoice,
  addInvoices,
  removePaymentReason,
  resetPrepay,
  updatePaymentDataInfo,
  updatePaymentRequest,
  updateCurrencyCode,
} = paymentDataSlice.actions;

export const selectPaymentData = (state) => state.paymentData;

export default paymentDataSlice.reducer;
