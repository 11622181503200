import { pickBy, size } from 'lodash';
import DashboardRecurrences from './DashboardRecurrences';
import DashboardScheduled from './DashboardScheduled';

const DashboardPayments = (props) => {
  const { isRecurrencePaymentEnabled, isScheduledPaymentEnabled } = props;

  const trueValues = pickBy(props, Boolean);

  const col = 12 / size(trueValues);

  const colClass = `col-12 col-md-${col} mb-4`;

  return (
    trueValues && (
      <>
        <div className="row">
          <div hidden={!isRecurrencePaymentEnabled} className={colClass}>
            <div className="h-100 d-flex flex-column">
              <DashboardRecurrences />
            </div>
          </div>

          <div hidden={!isScheduledPaymentEnabled} className={colClass}>
            <div className="h-100 d-flex flex-column">
              <DashboardScheduled />
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default DashboardPayments;
