import { HuiPanel } from 'handle-ui';
import { pickBy, size } from 'lodash';
import SummaryPanel from './SummaryPanel';
import SummaryPanelItem from './SummaryPanelItem';
import SummaryPanelTotal from './SummaryPanelTotal';

const DashboardInvoiceSummary = (props) => {
  const {
    invoicesSummary,
    isPaymentEnabled,
    isOpenProjectSummaryEnabled,
    isOpenWithoutProjectSummaryEnabled,
    isOpenAccountSummaryEnabled,
    isInvoicesPaidSummaryEnabled,
    openAccountSummaryLabel,
    openProjectSummaryLabel,
    openWithoutProjectSummaryLabel,
    invoicesPaidSummaryLabel,
  } = props || {};

  const {
    openProjectTotal,
    openProjectCurrentTotal,
    openProject30Total,
    openProject60Total,
    openProject90Total,
    openProject91Total,
    openAccountTotal,
    openAccountCreditTotal,
    openAccountCurrentTotal,
    openAccount30Total,
    openAccount60Total,
    openAccount90Total,
    openAccount91Total,
    openWithoutProjectCurrentTotal,
    openWithoutProject30Total,
    openWithoutProject60Total,
    openWithoutProject90Total,
    openWithoutProject91Total,
    openWithoutProjectTotal,
    projectPaidTotal,
    withoutProjectPaidTotal,
    accountPaidTotal,
  } = invoicesSummary.data || {};

  const { currencyCode } = invoicesSummary;

  const countCols = {
    isOpenProjectSummaryEnabled,
    isOpenWithoutProjectSummaryEnabled,
    isOpenAccountSummaryEnabled,
    isInvoicesPaidSummaryEnabled,
  };

  const trueValues = pickBy(countCols, Boolean);
  const widgetsCount = size(trueValues);
  const cols = widgetsCount === 1 ? 6 : 12 / widgetsCount;

  const colClass = `col-12 col-md-6 mb-4 col-xl-${cols}`;

  const projectPaidColor = projectPaidTotal > 0 ? 'green' : 'gray';
  const withoutProjectPaidColor = withoutProjectPaidTotal > 0 ? 'green' : 'gray';
  const summaryPanelType = isPaymentEnabled ? 'pay' : 'view';

  return (
    <>
      <div className="row">
        <div className={colClass} hidden={!isOpenAccountSummaryEnabled}>
          <HuiPanel className="h-100 d-flex flex-column pt-3 flex-grow-1" boxShadow>
            <div className="h-100">
              <div className="px-3 hui-text-lg fw-bold">{openAccountSummaryLabel}</div>
              <SummaryPanel
                title="Current"
                currencyCode={currencyCode}
                amount={openAccountCurrentTotal}
                amountColor="green"
                type={summaryPanelType}
                daysPastDue={0}
                isPaid={false}
              />

              <SummaryPanel
                title="1-30 Days Past Due"
                currencyCode={currencyCode}
                amount={openAccount30Total}
                amountColor="black-light"
                type={summaryPanelType}
                daysPastDue={30}
                isPaid={false}
              />

              <SummaryPanel
                title="31-60 Days Past Due"
                currencyCode={currencyCode}
                amount={openAccount60Total}
                amountColor="yellow"
                type={summaryPanelType}
                daysPastDue={60}
                isPaid={false}
              />

              <SummaryPanel
                title="61-90 Days Past Due"
                currencyCode={currencyCode}
                amount={openAccount90Total}
                amountColor="red"
                type={summaryPanelType}
                daysPastDue={90}
                isPaid={false}
              />

              <SummaryPanel
                title="91+ Days Past Due"
                currencyCode={currencyCode}
                amount={openAccount91Total}
                amountColor="red-dark"
                type={summaryPanelType}
                daysPastDue={91}
                isPaid={false}
              />
            </div>

            <SummaryPanelTotal
              title={'Total ' + openAccountSummaryLabel}
              currencyCode={currencyCode}
              amount={openAccountTotal}
              type={summaryPanelType}
              isPaid={false}
            />

            <SummaryPanelTotal
              hidden={openAccountCreditTotal === 0}
              title="Total Open Account Credits"
              currencyCode={currencyCode}
              amount={openAccountCreditTotal}
              amountColor="green"
              type="viewCredit"
              isPaid={false}
              invoiceType="CREDIT"
            />
          </HuiPanel>
        </div>

        <div className={colClass} hidden={!isOpenProjectSummaryEnabled}>
          <HuiPanel className="h-100 d-flex flex-column pt-3 flex-grow-1" boxShadow>
            <div className="h-100">
              <div className="px-3 hui-text-lg fw-bold">{openProjectSummaryLabel}</div>
              <SummaryPanel
                title="Current"
                currencyCode={currencyCode}
                amount={openProjectCurrentTotal}
                amountColor="green"
                type={summaryPanelType}
                hasProject={true}
                daysPastDue={0}
                isPaid={false}
              />

              <SummaryPanel
                title="1-30 Days Past Due"
                currencyCode={currencyCode}
                amount={openProject30Total}
                amountColor="black-light"
                type={summaryPanelType}
                hasProject={true}
                daysPastDue={30}
                isPaid={false}
              />

              <SummaryPanel
                title="31-60 Days Past Due"
                currencyCode={currencyCode}
                amount={openProject60Total}
                amountColor="yellow"
                type={summaryPanelType}
                hasProject={true}
                daysPastDue={60}
                isPaid={false}
              />

              <SummaryPanel
                title="61-90 Days Past Due"
                currencyCode={currencyCode}
                amount={openProject90Total}
                amountColor="red"
                type={summaryPanelType}
                hasProject={true}
                daysPastDue={90}
                isPaid={false}
              />

              <SummaryPanel
                title="91+ Days Past Due"
                currencyCode={currencyCode}
                amount={openProject91Total}
                amountColor="red-dark"
                type={summaryPanelType}
                hasProject={true}
                daysPastDue={91}
                isPaid={false}
              />
            </div>
            <SummaryPanelTotal
              title={'Total ' + openProjectSummaryLabel}
              currencyCode={currencyCode}
              amount={openProjectTotal}
              type={summaryPanelType}
              hasProject={true}
              isPaid={false}
            />
          </HuiPanel>
        </div>

        <div className={colClass} hidden={!isOpenWithoutProjectSummaryEnabled}>
          <HuiPanel className="h-100 d-flex flex-column pt-3 flex-grow-1" boxShadow>
            <div className="h-100">
              <div className="px-3 hui-text-lg fw-bold">{openWithoutProjectSummaryLabel}</div>

              <SummaryPanel
                title="Current"
                currencyCode={currencyCode}
                amount={openWithoutProjectCurrentTotal}
                amountColor="green"
                type={summaryPanelType}
                hasProject={false}
                daysPastDue={0}
                isPaid={false}
              />

              <SummaryPanel
                title="1-31 Days Past Due"
                currencyCode={currencyCode}
                amount={openWithoutProject30Total}
                amountColor="black-light"
                type={summaryPanelType}
                hasProject={false}
                daysPastDue={30}
                isPaid={false}
              />

              <SummaryPanel
                title="31-60 Days Past Due"
                currencyCode={currencyCode}
                amount={openWithoutProject60Total}
                amountColor="yellow"
                type={summaryPanelType}
                hasProject={false}
                daysPastDue={60}
                isPaid={false}
              />

              <SummaryPanel
                title="61-90 Days Past Due"
                currencyCode={currencyCode}
                amount={openWithoutProject90Total}
                amountColor="red"
                type={summaryPanelType}
                hasProject={false}
                daysPastDue={90}
                isPaid={false}
              />

              <SummaryPanel
                title="91+ Days Past Due"
                currencyCode={currencyCode}
                amount={openWithoutProject91Total}
                amountColor="red-dark"
                type={summaryPanelType}
                hasProject={false}
                daysPastDue={91}
                isPaid={false}
              />
            </div>
            <SummaryPanelTotal
              title={'Total ' + openWithoutProjectSummaryLabel}
              currencyCode={currencyCode}
              amount={openWithoutProjectTotal}
              type={summaryPanelType}
              hasProject={false}
              isPaid={false}
            />
          </HuiPanel>
        </div>

        <div className={colClass} hidden={!isInvoicesPaidSummaryEnabled}>
          <HuiPanel className="h-100 d-flex flex-column pt-3 flex-grow-1" boxShadow>
            <div className="h-100">
              <div className="px-3 hui-text-lg fw-bold">{invoicesPaidSummaryLabel}</div>

              <SummaryPanelItem
                link="/history/invoices?hasProject=true&isPaid=true&type=INVOICE&size=100"
                title="With Project"
                label="View"
                color={projectPaidColor}
                currencyCode={currencyCode}
                amount={projectPaidTotal}
                disabled={projectPaidTotal === '0'}
              />

              <SummaryPanelItem
                link="/history/invoices?hasProject=false&isPaid=true&type=INVOICE&size=100"
                title="Without Project"
                label="View"
                color={withoutProjectPaidColor}
                currencyCode={currencyCode}
                amount={withoutProjectPaidTotal}
                disabled={withoutProjectPaidTotal === '0'}
              />
            </div>

            <SummaryPanelItem
              link="/history/invoices?isPaid=true&type=INVOICE&size=100"
              title={`Total ${invoicesPaidSummaryLabel}`}
              label="View"
              color="black"
              currencyCode={currencyCode}
              amount={accountPaidTotal}
              disabled={accountPaidTotal <= 0}
              className="border-top px-3 py-2 hui-dashboard-summary d-flex align-items-center hui-bg-gray-light"
              textClass="hui-text-xs fw-bold text-uppercase hui-color-gray mb-0"
            />
          </HuiPanel>
        </div>
      </div>
    </>
  );
};

export default DashboardInvoiceSummary;
