import { size, startCase, toLower } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { formatCurrency } from '../../../utils/utilsCurrency';
import { getUtilsTextColorByPaymentStatus } from '../../../utils/utilsTexts';

const componentType = {
  processed: { title: 'Processed Payments', status: 'PROCESSED' },
  future: { title: 'Future Payments', status: 'SCHEDULED' },
};

const SidebarRecurrencePayments = (props) => {
  const { type, recurrence, hideButton, hideTitle } = props;
  const { payments } = recurrence || {};

  const selectedType = componentType[type];

  const paymentsFiltered =
    (payments &&
      payments?.filter((p) => {
        return (
          (selectedType.status === 'SCHEDULED' && p.status === 'SCHEDULED') ||
          (selectedType.status === 'PROCESSED' && p.status !== 'SCHEDULED')
        );
      })) ||
    {};

  paymentsFiltered.sort((a, b) => b.recurrenceNumber - a.recurrenceNumber);

  return (
    !!size(paymentsFiltered) && (
      <>
        <div
          className="recurrence-payments-title d-flex mt-3"
          hidden={selectedType.status === 'PROCESSED' || hideTitle}
        >
          <span>{selectedType.title}</span>
        </div>

        <div className="recurrence-payments d-flex flex-column flex-column-reverse mt-3">
          {paymentsFiltered.map((payment, i) => {
            const paymentDate =
              payment.voidedAt ||
              payment.refundedAt ||
              payment.chargebackAt ||
              payment.approvedAt ||
              payment.scheduledAt;

            const eventTextColor = `${getUtilsTextColorByPaymentStatus[payment.status] || 'hui-color-gray'}`;
            const recurrenceTitle = `fw-bold ${eventTextColor}`;

            const recurrenceIcon = `recurrence-payments-icon d-flex flex-column align-items-center justify-content-center ${toLower(
              payment.status,
            )}`;

            const totalFormatted = formatCurrency(payment.total, payment.currencyCode);

            return (
              <div key={i} className="recurrence-payments-item d-flex flex-column flex-md-row">
                <div className="recurrence-payments-inner d-flex pr-3 pr-md-0 align-items-center">
                  <div className="flex-grow-1">
                    <div className={recurrenceIcon}>
                      <div className="recurrence-payments-num">{payment.recurrenceNumber}</div>
                      <div className="recurrence-payments-total">of {recurrence.quantity}</div>
                    </div>
                    <h3 className="hui-text mb-0">
                      <span className={recurrenceTitle}>{startCase(toLower(payment.status))}</span>
                      &nbsp;-&nbsp;
                      <span className="hui-color-black-light">{moment(paymentDate).format('M/DD/YYYY')}</span>
                    </h3>
                    <h2 className="hui-text hui-bold">{totalFormatted}</h2>
                    <div className="hui-text-sm hui-color-black-light">{payment.code}</div>
                  </div>

                  <div>
                    {!hideButton && (
                      <a
                        href={`/history/payments/${payment.code}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hui-btn hui-btn hui-color-black ms-2"
                      >
                        View Payment
                      </a>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </>
    )
  );
};

export default SidebarRecurrencePayments;
