export const DEFAULT_CURRENCY_CODE = 'USD';

const currencyLabel = {
  USD: 'US Dollar',
  CAD: 'Canadian Dollar',
};

export function getCurrencyCode(currencyCode) {
  return currencyCode ?? DEFAULT_CURRENCY_CODE;
}

export function formatCurrency(value, currencyCode) {
  const normalizeValue = Object.is(value, -0) || !value ? 0 : value;

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: getCurrencyCode(currencyCode),
  });

  return formatter.format(normalizeValue);
}

export function getCurrencyPrefix(currencyCode) {
  const formatted = formatCurrency(0, currencyCode);
  const prefix = formatted.replace(/\d|,|\.|-/g, '').trim();
  return prefix;
}

export function getCurrencyLabel(currencyCode) {
  return currencyLabel[currencyCode];
}
