import { size } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectInvoicesPayment } from '../../../../redux/InvoicesPaymentSlice';
import { selectPaymentData } from '../../../../redux/PaymentDataSlice';
import { selectPortalInfo } from '../../../../redux/PortalInfoSlice';
import UtilPaymentMethods from '../../../../utils/utilPaymentMethods';
import { formatCurrency } from '../../../../utils/utilsCurrency';
import { calculateSurcharge, surchargeRateStr } from '../../../../utils/utilsSurcharge';

export function surchargeEnabled(isSurchargeEnabled, paymentType, cardType, state, currencyCode) {
  return (
    isSurchargeEnabled &&
    UtilPaymentMethods.isCreditCard(paymentType) &&
    cardType === 'CREDIT' &&
    !['CT', 'MA'].includes(state || '') &&
    currencyCode && currencyCode === 'USD'
  );
}

const HuiPaymentSummary = (props) => {
  const { payment, title = 'Summary', hideTitle = false } = props;
  const invoicesPayment = useSelector(selectInvoicesPayment);
  const { state, paymentType, cardType } = invoicesPayment;
  const dispatchInvoices = useSelector(selectPaymentData);
  const portalInfo = useSelector(selectPortalInfo);

  const { settings: { isSurchargeEnabled = false, isCreditMemoEnabled = false } = {} } = portalInfo;

  const paymentData = payment || dispatchInvoices || {};
  const { subtotal = 0, total = 0, credit, isPrepaid, currencyCode } = paymentData;
  const hasInvoices = !!size(paymentData.paymentInvoices);
  const invoiceWord = size(paymentData.paymentInvoices) > 1 ? 'Invoices' : 'Invoice';

  const showSurcharge = surchargeEnabled(isSurchargeEnabled, paymentType, cardType, state, currencyCode);
  const showSurchargeColorado = showSurcharge && state === 'CO';

  const creditFormatted = formatCurrency(credit * -1, currencyCode);
  const subtotalFormatted = formatCurrency(subtotal, currencyCode);
  const surchargeFormatted = formatCurrency(calculateSurcharge(total), currencyCode);

  const paymentAmountFormatted = formatCurrency(subtotal - credit, currencyCode);
  const totalSurchargeFormatted = formatCurrency(subtotal - credit + calculateSurcharge(total), currencyCode);

  const summaryClass = hideTitle ? 'd-flex' : 'd-flex px-3';

  return (
    <>
      <div className={`${summaryClass} border-top`} hidden={hideTitle}>
        <div className="flex-grow-1 hui-text-lg fw-bold mb-2">{title}</div>
      </div>

      <div className={summaryClass} hidden={isPrepaid || !hasInvoices}>
        <div className="flex-grow-1 hui-text fw-bold mb-2">Total {invoiceWord}</div>
        <div>
          <strong>{subtotalFormatted}</strong>
        </div>
      </div>

      <div className={summaryClass} hidden={!isCreditMemoEnabled || isPrepaid}>
        <div className="flex-grow-1 hui-text fw-bold mb-2">Total Credit Applied</div>
        <div>
          <strong>{creditFormatted}</strong>
        </div>
      </div>

      <div className={summaryClass}>
        <div className="flex-grow-1 hui-text fw-bold mb-2">Subtotal</div>
        <div>
          <strong>{paymentAmountFormatted}</strong>
        </div>
      </div>

      <div className={summaryClass} hidden={!showSurcharge}>
        <div className="flex-grow-1 hui-text fw-bold mb-2">Credit Card Surcharge ({surchargeRateStr}):</div>
        <div>
          <strong>{surchargeFormatted}</strong>
        </div>
      </div>

      <div className={summaryClass} hidden={!showSurcharge}>
        <div className="hui-bg-gray-light rounded-3 p-2 hui-text-sm mb-2 d-flex align-items-center">
          <div className="d-flex me-1">
            <i className="hui-i hui-svg-info-gray-sm"></i>
          </div>
          <div className="flex-grow-1">
            To cover the cost of accepting credit cards, we collected a {surchargeRateStr} credit card surcharge.
          </div>
        </div>
      </div>

      <div className={`${summaryClass} mb-3`}>
        <div className="flex-grow-1 hui-text-lg fw-bold mb-2">{!showSurcharge ? 'Total' : 'Total + Surcharge Fee'}</div>
        <div className="hui-text-lg">
          <strong>{!showSurcharge ? paymentAmountFormatted : totalSurchargeFormatted}</strong>
        </div>
      </div>

      <div className={summaryClass} hidden={!showSurchargeColorado}>
        <div className="mt-4 hui-bg-gray-light rounded-3 p-2 hui-text-sm mb-3">
          <div className="d-flex align-items-start">
            <div className="d-flex me-1">
              <i className="hui-i hui-svg-info-sm"></i>
            </div>
            <div className="flex-grow-1">
              <h4 className="hui-text fw-bold">Colorado:</h4>
              To cover the cost of processing a credit or charge card transaction, and pursuant to section 5-2-212,
              colorado revised statutes, a seller or lessor may impose a processing surcharge in an amount not to exceed
              the merchant discount fee that the seller or lessor incurs in processing the sales or lease transaction. A
              seller or lessor shall not impose a processing surcharge on payments made by use of cash, a check, or a
              debit card or redemption of a gift card.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HuiPaymentSummary;
