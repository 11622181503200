import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isEmpty, size } from 'lodash';
import { selectCurrencyCode, updateCurrencyCode, updateCurrencyCodeList } from '../../redux/CurrencyCodeSlice';
import { openModal } from '../../redux/ModalSlice';
import { selectPortalInfo } from '../../redux/PortalInfoSlice';
import { getCurrencyLabel } from '../../utils/utilsCurrency';
import { getLocalStorageItem } from '../localStorage/HuiLocalStorage';

const CurrencySelector = (props) => {
  const { hidden, style } = props;
  const dispatch = useDispatch();
  const portalInfo = useSelector(selectPortalInfo);
  const { currencyCode, currencyCodeList } = useSelector(selectCurrencyCode);
  const showComponent = !hidden && size(currencyCodeList) > 1;
  const { currencyCodes } = portalInfo?.settings;

  useEffect(() => {
    if (!isEmpty(currencyCodes) && isEmpty(currencyCodeList)) {
      dispatch(updateCurrencyCodeList(currencyCodes));
      const localCurrencyCode = getLocalStorageItem('currencyCode');
      if (localCurrencyCode && currencyCodes.includes(localCurrencyCode)) {
        dispatch(updateCurrencyCode(localCurrencyCode));
      }
    }
  }, [dispatch, currencyCodes, currencyCodeList]);

  return (
    showComponent && (
      <div style={style}>
        <div
          className="border px-2 py-1 d-flex align-items-center hui-hover me-3"
          role="button"
          onClick={() => handleChange()}
        >
          <div className="d-flex me-3">
            <i className={`hui-svg-${currencyCode}`}></i>
          </div>

          <div className="d-flex flex-column flex-grow-1">
            <div></div>
            <div>{getCurrencyLabel(currencyCode)}</div>
          </div>
        </div>
      </div>
    )
  );

  function handleChange() {
    const modal = {
      type: 'currencySelector',
      showConfirmButton: false,
      showCancelButton: false,
      isOpen: true,
      keepOpen: true,
      title: 'Select your currency',
      hideClose: true,
      hideActions: true,
    };
    dispatch(openModal(modal));
  }
};

export default CurrencySelector;
