import { toUpper } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrencyCode, updateCurrencyCode } from '../../../redux/CurrencyCodeSlice';
import { closeModal } from '../../../redux/ModalSlice';
import { getCurrencyLabel } from '../../../utils/utilsCurrency';
import { setLocalStorageItem } from '../../localStorage/HuiLocalStorage';

const ModalCurrencySelector = () => {
  const dispatch = useDispatch();
  const { currencyCode, currencyCodeList } = useSelector(selectCurrencyCode);

  const sortedCurrencyList = [currencyCode, ...currencyCodeList.filter((code) => code !== currencyCode)];

  return (
    <>
      <div>By choosing a currency, all data, invoices, and reports will be displayed in the selected currency.</div>

      {sortedCurrencyList.map((code, i) => {
        const activeClass = `border hui-radius px-3 py-2 d-flex align-items-center mt-3 hui-hover ${
          currencyCode === code ? 'active hui-bg-gray-light' : ''
        }`;
        const flagClass = `hui-svg-${code}`;

        return (
          <div className={activeClass} key={i} role="button" onClick={() => handleChange(code)}>
            <div className="d-flex me-3">
              <i className={flagClass}></i>
            </div>

            <div className="d-flex flex-column flex-grow-1">
              <div hidden={!getCurrencyLabel(code)}>{getCurrencyLabel(code)}</div>
              <div>{toUpper(code)}</div>
            </div>

            <div hidden={currencyCode !== code} className="d-flex">
              <i className="hui-i hui-svg-check-sm"></i>
            </div>
          </div>
        );
      })}
    </>
  );

  async function handleChange(code) {
    setLocalStorageItem('currencyCode', code);
    await dispatch(updateCurrencyCode(code));
    dispatch(closeModal({}));
  }
};

export default ModalCurrencySelector;
