import { isBoolean, isNumber } from 'lodash';
import SummaryPanelItem from './SummaryPanelItem';

const componentType = {
  pay: { link: '/pay?size=100' },
  view: { label: 'View', link: '/history/invoices?type=INVOICE&size=100' },
};

const SummaryPanel = (props) => {
  const { title, amount, amountColor = 'black', type, daysPastDue, hasProject, isPaid, hidden, currencyCode } = props;

  const selectedType = componentType[type];

  const url = new URL(selectedType.link, document.baseURI);

  if (isBoolean(hasProject)) {
    url.searchParams.append('hasProject', hasProject);
  }
  if (isNumber(daysPastDue)) {
    url.searchParams.append('daysPastDue', daysPastDue);
  }
  if (isBoolean(isPaid) && type === 'view') {
    url.searchParams.append('isPaid', isPaid);
  }

  const link = url.pathname + url.search;

  const { label } = selectedType;

  return (
    <>
      <SummaryPanelItem
        link={link}
        hidden={hidden}
        title={title}
        label={label}
        color={amountColor}
        currencyCode={currencyCode}
        amount={amount}
        disabled={amount <= 0}
      />
    </>
  );
};

export default SummaryPanel;
