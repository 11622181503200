import React from 'react';
import PaymentTotal from './PaymentTotal';

const PaymentSubtotalSelect = (props) => {
  const { disabled, subtotal, credit, onClick, viewCredits, link, currencyCode } = props;

  return (
    <div className="d-flex flex-column flex-md-row align-items-center">
      <div className="d-flex align-items-center">
        <div>
          <PaymentTotal title="Subtotal" currencyCode={currencyCode} amount={subtotal} />
        </div>

        <div hidden={!credit} className="px-2 hui-text-lg fw-bold">
          -
        </div>

        <div className="pe-3">
          <PaymentTotal
            hidden={!credit}
            viewCredits={viewCredits}
            link={link}
            title="Credit Applied"
            currencyCode={currencyCode}
            amount={credit}
          />
        </div>

        <div className="pe-3">
          <PaymentTotal title="Total Payment" currencyCode={currencyCode} amount={subtotal - credit} />
        </div>
      </div>
      <button type="button" className="hui-btn hui-btn-lg hui-btn-green" onClick={onClick} disabled={disabled}>
        Select the Payment Method
      </button>
    </div>
  );
};

export default PaymentSubtotalSelect;
