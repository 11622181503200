import { HuiPanel, HuiPanelContent } from 'handle-ui';
import { size } from 'lodash';
import moment from 'moment';
import HuiTable from '../../components/table/HuiTable';
import { formatCurrency } from '../../utils/utilsCurrency';
import HuiTableBalance from '../table/HuiTableBalance';
import HuiTableCell from '../table/HuiTableCell';
import HuiTableInvoiceDate from '../table/HuiTableInvoiceDate';
import HuiTableTotal from '../table/HuiTableTotal';

function checkUndefinedFields(invoices, customFields) {
  const missingFields = new Set(customFields);

  customFields.forEach((field) => {
    let fieldExists = false;

    for (const row of invoices) {
      if (row.hasOwnProperty(field)) {
        fieldExists = true;
        break;
      }
    }

    if (fieldExists) {
      missingFields.delete(field);
    }
  });

  return Array.from(missingFields);
}

function getColumns(invoices) {
  const undefinedFields = checkUndefinedFields(invoices, ['jobId', 'poNumber']);

  let columns = [
    {
      field: 'createdAt',
      label: 'Invoice Date',
      width: '83px',
      getValue: ({ value }) => moment(value).format('M/DD/YYYY'),
      cellRenderer: (props) => <HuiTableInvoiceDate {...props} />,
    },
    {
      field: 'dueDate',
      label: 'Due Date',
      width: '87px',
      getValue: ({ value }) => moment(value).format('M/DD/YYYY'),
      cellRenderer: (props) => <HuiTableInvoiceDate {...props} />,
    },
    {
      field: 'invoiceNumber',
      label: 'Invoice Number',
      width: '100px',
      cellRenderer: (props) => <HuiTableCell {...props} />,
    },
    {
      field: 'jobId',
      label: 'Job ID',
      width: '100px',
      cellRenderer: (props) => <HuiTableCell {...props} />,
      visible: !undefinedFields.includes('jobId'),
    },
    {
      field: 'poNumber',
      label: 'PO Number',
      width: '100px',
      cellRenderer: (props) => <HuiTableCell {...props} />,
      visible: !undefinedFields.includes('poNumber'),
    },
    {
      field: 'totalAmount',
      label: 'Total Amount',
      width: '100px',
      className: 'text-end',
      headerCellRenderer: ({ column }) => <div className="d-block w-100 text-end">{column.label}</div>,
      getValue: ({ value, rowData }) => formatCurrency(value, rowData.currencyCode),
      cellRenderer: (props) => <HuiTableTotal {...props} />,
    },
    {
      field: 'currentBalance',
      label: 'Balance Due',
      width: '100px',
      className: 'text-end',
      headerCellRenderer: ({ column }) => <div className="d-block w-100 text-end">{column.label}</div>,
      getValue: ({ value, rowData }) => formatCurrency(value, rowData.currencyCode),
      cellRenderer: (props) => <HuiTableBalance className={'hui-color-red'} {...props} />,
    },
  ];

  columns = columns.map((column, i) => {
    return { ...column, id: i, sortable: false };
  });

  return columns;
}

const HuiPanelPaymentInvoices = (props) => {
  const { invoices } = props;
  const isLoading = size(invoices) ? false : true;

  const count = size(invoices);
  return (
    <div>
      <HuiPanel noBorder boxShadow className="overflow-hidden position-relative mb-3">
        <HuiPanelContent className="d-flex align-items-center py-0 px-2">
          <HuiTable
            isLoading={isLoading}
            className="hui-table hui-table-no-border hui-table-fluid"
            columns={getColumns(invoices)}
            rows={invoices}
            totalRows={count}
            isPaginated={false}
          />
        </HuiPanelContent>
      </HuiPanel>
    </div>
  );
};

export default HuiPanelPaymentInvoices;
