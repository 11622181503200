import { Divider } from '@mui/material';
import * as React from 'react';
import { formatCurrency } from '../../../utils/utilsCurrency';

const SidebarRecurrenceBalance = (props) => {
  const { recurrence } = props;

  const { amount = 0, quantity = 0, currencyCode } = recurrence;
  const total = amount * quantity;

  return (
    <>
      <div className="d-flex">
        <div className="flex-grow-1 text-start">
          <h3 className="hui-text fw-bold mb-0">Amount: </h3>
        </div>
        <div className="text-end">{formatCurrency(amount, currencyCode)}</div>
      </div>

      <Divider className="my-1" />

      <div className="d-flex">
        <div className="flex-grow-1 text-start">
          <h3 className="hui-text fw-bold mb-0">Quantity: </h3>
        </div>
        <div className="text-end">{quantity}</div>
      </div>

      <Divider className="my-1" />

      <div className="d-flex">
        <div className="flex-grow-1 text-start">
          <h3 className="hui-text fw-bold mb-0">Total: </h3>
        </div>
        <div className="text-end">{formatCurrency(total, currencyCode)}</div>
      </div>
    </>
  );
};

export default SidebarRecurrenceBalance;
