import { HuiPanel, HuiPanelContent, HuiPanelHeader } from 'handle-ui';
import { startCase, toLower } from 'lodash';
import moment from 'moment/moment';
import React from 'react';
import HuiHistoryPaymentInvoices from '../payment/confirm-payment/HuiHistoryPaymentInvoices';
import HuiPaymentInfo from '../payment/confirm-payment/HuiPaymentInfo';
import HuiPaymentRequestDetails from '../payment/confirm-payment/HuiPaymentRequestDetails';
import HuiRecurrenceInfo from '../payment/confirm-payment/HuiRecurrenceInfo';

const HuiFullPaymentsDetail = (props) => {
  const { payment } = props || {};

  const { info, externalCode, status, paymentType, approvedAt, createdAt, isPrepaid, isOneTimePay, recurrenceId } =
    payment || {};

  const { name, email } = info || {};

  const internalType = isPrepaid ? 'Prepay' : 'Invoices';
  const checkout = isOneTimePay ? 'One Time Payment' : '';

  const type = checkout || internalType;

  return (
    <HuiPanel boxShadow>
      <HuiPanelHeader title="Payment Details" borderBottom />

      <HuiPanelContent className="px-0 py-2">
        <div className="d-flex px-3">
          <div className="flex-grow-1 hui-text fw-bold mb-2">External Code</div>
          <div>{externalCode || 'N/A'}</div>
        </div>

        <div className="d-flex px-3">
          <div className="flex-grow-1 hui-text fw-bold mb-2">Payment Type</div>
          <div>{type}</div>
        </div>

        <div className="d-flex px-3">
          <div className="flex-grow-1 hui-text fw-bold mb-2">Payment Status</div>
          <div>{startCase(toLower(status))}</div>
        </div>

        <div className="d-flex px-3">
          <div className="flex-grow-1 hui-text fw-bold mb-2">Payment Method</div>
          <div>{startCase(toLower(paymentType))}</div>
        </div>

        <div className="d-flex px-3" hidden={!createdAt}>
          <div className="flex-grow-1 hui-text fw-bold mb-2">Created</div>
          <div>{moment(createdAt).format('M/DD/YYYY - h:mm:ss a')}</div>
        </div>

        <div className="d-flex px-3" hidden={!approvedAt}>
          <div className="flex-grow-1 hui-text fw-bold mb-2">Paid</div>
          <div>{moment(approvedAt).format('M/DD/YYYY - h:mm:ss a')}</div>
        </div>

        <div className="d-flex px-3">
          <div className="flex-grow-1 hui-text fw-bold mb-2">Paid by</div>
          <div>
            {name} - {email}
          </div>
        </div>
      </HuiPanelContent>

      <HuiPanelContent className="p-0">
        <HuiPaymentRequestDetails payment={payment} />
      </HuiPanelContent>

      <HuiPanelContent className="p-0">
        <HuiPaymentInfo payment={payment} />
      </HuiPanelContent>

      <HuiPanelContent className="p-0" hidden={!recurrenceId}>
        <HuiRecurrenceInfo payment={payment} />
      </HuiPanelContent>

      <HuiPanelContent className="p-0">
        <HuiHistoryPaymentInvoices payment={payment} />
      </HuiPanelContent>
    </HuiPanel>
  );
};

export default HuiFullPaymentsDetail;
