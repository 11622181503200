import { HuiAlert, HuiPanel, HuiPanelChild, HuiPanelContent, HuiPanelHeader } from 'handle-ui';
import { get, isNull } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import toastr from 'toastr';
import { getCheckoutToken } from '../../../actions/invoices/getCheckoutToken';
import { getCreditInvoices } from '../../../actions/invoices/getCreditInvoices';
import { getInvoicesSummary } from '../../../actions/invoices/getInvoicesSummary';
import { getUnpaidInvoices } from '../../../actions/invoices/getUnpaidInvoices';
import CurrencySelector from '../../../components/payment/CurrencySelector';
import { resetCredits, selectCredits, updateCredits } from '../../../redux/CreditsSlice';
import { selectCurrencyCode } from '../../../redux/CurrencyCodeSlice';
import {
  resetInvoicesPayment,
  selectInvoicesPayment,
  updatePaymentMethod,
  updateToken,
} from '../../../redux/InvoicesPaymentSlice';
import { resetInvoices, updateInvoices } from '../../../redux/InvoicesSlice';
import {
  resetPaymentData,
  selectPaymentData,
  updateBalance,
  updateCurrencyCode,
  updateOpenAccountTotal,
} from '../../../redux/PaymentDataSlice';
import { selectPortalInfo } from '../../../redux/PortalInfoSlice';
import UtilPaymentMethods from '../../../utils/utilPaymentMethods';
import { scrollToPanel } from '../../../utils/utilsScrollTo';
import PayInvoices from './PayInvoices';
import PaymentSubtotal from './PaymentSubtotal';
import Prepay from './Prepay';
import HuiConfirmPayment from './confirm-payment/HuiConfirmPayment';
import HuiPaymentType from './payment-type/HuiPaymentType';
import HuiSelectPayment from './select-payment/HuiSelectPayment';

const Pay = () => {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const payment = useSelector(selectInvoicesPayment);
  const portalInfo = useSelector(selectPortalInfo);
  const paymentData = useSelector(selectPaymentData);
  const credits = useSelector(selectCredits);
  const { currencyCode } = useSelector(selectCurrencyCode);
  const creditsCount = credits.count;
  const isWithinIframe = window.parent !== window;

  const {
    subtotal = 0,
    total = 0,
    openAccountTotal,
    credit,
    paymentTypeError = false,
    currencyCode: paymentCurrencyCode,
  } = paymentData;

  const isAchEnabled = get(portalInfo, 'settings.isAchEnabled');
  const isCreditCardEnabled = get(portalInfo, 'settings.isCreditCardEnabled');
  const isPayWithCreditEnabled = get(portalInfo, 'settings.isPayWithCreditEnabled');
  const isPrepayEnabled = get(portalInfo, 'settings.isPrepayEnabled');
  const isPrepayForAdminEnabled = get(portalInfo, 'settings.isPrepayAdminEnabled');
  const showPrepay = isPrepayEnabled || (isWithinIframe && isPrepayForAdminEnabled);

  const isPayWithCreditOnly = subtotal === credit && subtotal > 0;

  const showPayWithCreditMessage = isPayWithCreditEnabled && isPayWithCreditOnly;

  const minimumAchPayment = get(portalInfo, 'settings.minAchPayment');
  const minimumCreditCardPayment = get(portalInfo, 'settings.minCreditCardPayment');

  const getMinimumPayment = Math.min(minimumAchPayment, minimumCreditCardPayment);

  const checkPaymentSubtotal = isPayWithCreditOnly || total >= getMinimumPayment;

  const [isSelectPaymentOpen, setIsSelectPaymentOpen] = useState(false);
  const [isConfirmPaymentOpen, setIsConfirmPaymentOpen] = useState(false);

  const showSelectPayment = isSelectPaymentOpen && !paymentTypeError;
  const showConfirmPayment =
    isConfirmPaymentOpen &&
    checkPaymentSubtotal &&
    ((isPayWithCreditOnly && payment.paymentType === 'CREDIT') ||
      (!isPayWithCreditOnly && payment.paymentType !== 'CREDIT'));

  const refMakePayment = useRef(null);
  const refSelectPayment = useRef(null);
  const refConfirmPayment = useRef(null);

  const paymentTab = pathname.substring(pathname.lastIndexOf('/') + 1).split('-')[0];

  const onUnmount = React.useRef();

  useEffect(() => {
    async function fetchOpenAccountTotal(currencyCode, paymentCurrencyCode) {
      if (isNull(openAccountTotal) || paymentCurrencyCode !== currencyCode) {
        const invoicesSummary = await getInvoicesSummary({ currencyCode });
        const _openAccountTotal = Number(invoicesSummary.openAccountTotal || 0);
        dispatch(updateOpenAccountTotal(_openAccountTotal));
        dispatch(updateCurrencyCode(currencyCode));
      }
    }
    if (currencyCode) {
      fetchOpenAccountTotal(currencyCode, paymentCurrencyCode);
    }
  }, [dispatch, openAccountTotal, currencyCode, paymentCurrencyCode]);

  useEffect(() => {
    async function fetchInvoices(currencyCode) {
      if (!searchParams.get('size')) {
        searchParams.set('size', 20);
      }

      searchParams.set('currencyCode', currencyCode);

      dispatch(resetInvoices({}));
      const resultInvoices = await getUnpaidInvoices(searchParams);
      await dispatch(updateInvoices(resultInvoices));

      await dispatch(updateBalance(resultInvoices));
    }

    if (currencyCode) {
      fetchInvoices(currencyCode);
    }

    return () => onUnmount.current();
  }, [dispatch, searchParams, currencyCode]);

  useEffect(() => {
    async function fetchCredits(currencyCode) {
      const resultCredits = await getCreditInvoices({ currencyCode });
      dispatch(updateCredits(resultCredits));
    }

    if (currencyCode) {
      fetchCredits(currencyCode);
    }
  }, [dispatch, currencyCode]);

  onUnmount.current = () => {
    const resetData = () => {
      dispatch(resetPaymentData({}));
      dispatch(resetInvoices({}));
      dispatch(resetInvoicesPayment({}));
      dispatch(resetCredits({}));
    };

    if (paymentData.currencyCode !== currencyCode) {
      resetData();
      dispatch(updateCurrencyCode(currencyCode));
    } else if (paymentData?.subtotal) {
      const { pathname = '' } = window.location || {};
      if (pathname !== '/pay' && pathname !== '/pay-slim') {
        resetData();
      }
    }
  };

  return (
    <div className="p-4 d-flex flex-grow-1 overflow-hidden">
      <HuiPanel className="w-100" boxShadow>
        <HuiPanelChild id="make-payment" ref={refMakePayment}>
          <CurrencySelector hidden={!isWithinIframe} style={{ position: 'absolute', top: '35px', right: '25px' }} />
          <HuiPanelHeader title="Make a payment" className="pt-3" stepNumber={1}>
            <HuiPaymentType activeType={paymentTab} isPrepayEnabled={showPrepay} onClick={huiPaymentTypeChange} />
          </HuiPanelHeader>

          <HuiPanelContent className="p-0" hidden={paymentTab === 'prepay'}>
            {paymentTab === 'pay' && <PayInvoices />}
            <PaymentSubtotal onClick={handleSelectPaymentMethod} />
          </HuiPanelContent>

          <HuiPanelContent className="p-4 d-flex" hidden={paymentTab === 'pay'}>
            {paymentTab === 'prepay' && <Prepay onClick={handleSelectPaymentMethod} currencyCode={currencyCode} />}
          </HuiPanelContent>
        </HuiPanelChild>

        <HuiPanelChild id="select-payment" ref={refSelectPayment}>
          <HuiPanelHeader title="Select the Payment Method" stepNumber={2} />
          <HuiPanelContent isOpen={showSelectPayment} className="px-3 pb-4">
            <HuiAlert
              hidden={!showPayWithCreditMessage}
              title="Your credits cover the full amount!"
              radius="xl"
              icon="info"
              background
              green
              size="lg"
              border
              className="hui-select-payment-alert mb-4 py-3 mx-auto"
            >
              Simply proceed by processing your payment with credits.
            </HuiAlert>

            <HuiSelectPayment
              selectAch={() => selectAch()}
              selectCard={() => selectCard()}
              selectCredit={() => selectCredit()}
            />
          </HuiPanelContent>
        </HuiPanelChild>

        <HuiPanelChild id="confirm-payment" ref={refConfirmPayment}>
          <HuiPanelHeader title="Confirm Payment" stepNumber={3} />
          <HuiPanelContent isOpen={showConfirmPayment} className="px-3 pb-4">
            <HuiConfirmPayment edit={handleEdit} isPayWithCreditOnly={isPayWithCreditOnly} />
          </HuiPanelContent>
        </HuiPanelChild>
      </HuiPanel>
    </div>
  );

  function handleEdit() {
    scrollToPanel(refMakePayment);
  }

  async function handleSelectPaymentMethod() {
    setIsSelectPaymentOpen(true);
    scrollToPanel(refSelectPayment);
  }

  async function selectAch() {
    if (!isAchEnabled) return false;
    try {
      if (!payment.checkoutToken) {
        const token = await getCheckoutToken();
        await dispatch(updateToken(token));
      }
      await dispatch(updatePaymentMethod(UtilPaymentMethods.ACH));
      setIsConfirmPaymentOpen(true);
      scrollToPanel(refConfirmPayment);
    } catch (e) {
      toastr.error('Please select again');
      console.error(e);
    }
  }

  async function selectCredit() {
    if (!isPayWithCreditEnabled) return false;
    await dispatch(updatePaymentMethod(UtilPaymentMethods.CREDIT));
    setIsConfirmPaymentOpen(true);
    scrollToPanel(refConfirmPayment);
  }

  async function selectCard() {
    if (!isCreditCardEnabled) return false;
    try {
      if (!payment.checkoutToken) {
        const token = await getCheckoutToken();
        await dispatch(updateToken(token));
      }
      await dispatch(updatePaymentMethod(UtilPaymentMethods.CREDIT_CARD));
      setIsConfirmPaymentOpen(true);
      scrollToPanel(refConfirmPayment);
    } catch (e) {
      toastr.error('Please select again');
      console.error(e);
    }
  }

  function huiPaymentTypeChange() {
    setIsSelectPaymentOpen(false);
    setIsConfirmPaymentOpen(false);
  }
};
export default Pay;
