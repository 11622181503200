import moment from 'moment/moment';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getAllRecurrences } from '../../../actions/recurrence/getAllRecurrences';
import CancelRecurrence from '../../../components/recurrence/CancelRecurrence';
import ViewRecurrence from '../../../components/recurrence/ViewRecurrence';
import HuiTable from '../../../components/table/HuiTable';
import HuiTableCell from '../../../components/table/HuiTableCell';
import HuiTableRecurrenceAmount from '../../../components/table/HuiTableRecurrenceAmount';
import HuiTableRecurrenceCount from '../../../components/table/HuiTableRecurrenceCount';
import HuiTableRecurrenceTotal from '../../../components/table/HuiTableRecurrenceTotal';
import HuiTableStatus from '../../../components/table/HuiTableStatus';
import HuiTableFooter from '../../../components/table/components/HuiTableFooter';
import HuiTableHeader from '../../../components/table/components/HuiTableHeader';
import { selectCurrencyCode } from '../../../redux/CurrencyCodeSlice';
import { resetPayment } from '../../../redux/PaymentSlice';
import { formatCurrency } from '../../../utils/utilsCurrency';
import { datePickerWithRangeFilter, idFilter, recurrenceStatusFilter } from '../../../utils/utilsFilters';

const getColumns = (onCancel = () => {}) => {
  let columns = [
    {
      field: 'status',
      label: 'Status',
      width: '105px',
      cellRenderer: (props) => <HuiTableStatus {...props} />,
    },
    {
      field: 'id',
      label: 'ID',
      width: '45px',
      cellRenderer: (props) => <HuiTableCell {...props} />,
    },
    {
      field: 'frequency',
      label: 'Frequency',
      width: 'auto',
      cellRenderer: (props) => <HuiTableCell {...props} />,
    },
    {
      field: 'createdAt',
      label: 'Originated',
      width: '90px',
      getValue: ({ value }) => (value !== null && moment(value).format('M/DD/YYYY')) || '',
      cellRenderer: (props) => <HuiTableCell {...props} />,
    },
    {
      field: 'startDate',
      label: 'Start Date',
      width: '90px',
      getValue: ({ value }) => (value !== null && moment(value).format('M/DD/YYYY')) || '',
      cellRenderer: (props) => <HuiTableCell {...props} />,
    },
    {
      field: 'endDate',
      label: 'End Date',
      width: '90px',
      getValue: ({ value }) => (value !== null && moment(value).format('M/DD/YYYY')) || '',
      cellRenderer: (props) => <HuiTableCell {...props} />,
    },

    {
      field: 'createdBy',
      label: 'Paid By',
      width: 'auto',
      getValue: ({ value }) => value.name,
      cellRenderer: (props) => <HuiTableCell {...props} />,
    },
    {
      field: 'paymentsCount',
      label: 'Payments',
      width: 'auto',
      getValue: (data) => `${data?.rowData?.processedCount} OF ${data.value} PAID`,
      cellRenderer: (props) => <HuiTableRecurrenceCount {...props} />,
    },
    {
      field: 'paymentsTotal',
      label: 'Total Amount',
      width: '160px',
      className: 'text-end',
      headerCellRenderer: ({ column }) => <div className="d-block w-100 text-end">{column.label}</div>,
      getValue: ({ value, rowData }) => formatCurrency(value, rowData.currencyCode),
      cellRenderer: (props) => <HuiTableRecurrenceTotal {...props} />,
    },
    {
      field: 'amount',
      label: 'Amount Paid',
      width: '160px',
      className: 'text-end',
      headerCellRenderer: ({ column }) => <div className="d-block w-100 text-end">{column.label}</div>,
      getValue: (data) => formatCurrency(data?.rowData?.processedCount * data?.value, data?.rowData.currencyCode),
      cellRenderer: (props) => <HuiTableRecurrenceAmount {...props} />,
    },

    {
      pinned: true,
      field: 'viewRecurrence',
      label: '',
      width: '100px',
      sortable: false,
      cellRenderer: (props) => (
        <>
          <ViewRecurrence id={props?.data?.id} icon {...props} onCancel={onCancel} />
          <CancelRecurrence className="hui-btn hui-btn-sm" recurrence={props?.data} onSuccess={onCancel}>
            Cancel
          </CancelRecurrence>
        </>
      ),
    },
  ];

  columns = columns.map((column, i) => {
    return { ...column, id: i, sortable: false };
  });

  return columns;
};

const HistoryRecurrences = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currencyCode } = useSelector(selectCurrencyCode);

  const [searchParams] = useSearchParams();

  searchParams.set('currencyCode', currencyCode);

  const rowsRef = useRef([]);

  const [allRows, setAllRows] = useState(rowsRef.current);
  const [totalRows, setTotalRows] = useState(null);
  const [load, setLoad] = useState(null);
  const [tableManager, setTableManager] = useState({});

  const paymentsHistoryFilters = {
    filters: [datePickerWithRangeFilter],
    advancedFilters: [idFilter, recurrenceStatusFilter],
  };

  const onRowsReset = () => {
    rowsRef.current = [];
    setAllRows(rowsRef.current);
    setTotalRows(null);
  };

  const onLoadTableManager = (tableManager) => {
    setTableManager(tableManager);
  };

  useEffect(() => {
    async function findRecurrences() {
      if (!searchParams.get('size')) {
        searchParams.set('size', tableManager.paginationApi.pageSize);
      }

      const result = await getAllRecurrences(searchParams);

      rowsRef.current = tableManager.asyncApi.mergeRowsAt(
        rowsRef.current,
        result.rows,
        (searchParams.get('page') - 1) * tableManager.paginationApi.pageSize,
      );
      setAllRows(rowsRef.current);
      setTotalRows(result.count);
    }
    if (tableManager.asyncApi && currencyCode) {
      findRecurrences();
    }
  }, [dispatch, searchParams, tableManager, load, currencyCode]);

  const isLoading = totalRows == null;

  return (
    <HuiTable
      additionalProps={{
        header: {
          showTableCountInfo: true,
          availableFilters: paymentsHistoryFilters,
        },
      }}
      onRowClick={({ data }) => redirectToPayment(data.code)}
      components={{ Header: HuiTableHeader, Footer: HuiTableFooter }}
      className="hui-table"
      isLoading={isLoading}
      isPaginated={true}
      columns={getColumns(loadData)}
      rows={allRows}
      totalRows={totalRows}
      contentName="Recurrences"
      onLoad={onLoadTableManager}
      onRowsRequest={() => {}}
      onRowsReset={onRowsReset}
    />
  );

  async function loadData(id) {
    setLoad(id);
  }

  async function redirectToPayment(code) {
    await dispatch(resetPayment({}));
    navigate(code);
  }
};

export default HistoryRecurrences;
