import { HuiInputAmount } from 'handle-ui';
import { filter, size } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { changeCreditTotal, selectCreditData, updateCredit } from '../../../../redux/CreditDataSlice';
import { selectSidebar, updateAmountWithCreditApplied } from '../../../../redux/SidebarSlice';
import { store } from '../../../../store/store';
import { formatCurrency, getCurrencyPrefix } from '../../../../utils/utilsCurrency';

const CreditMemoInputAmount = (props) => {
  const dispatch = useDispatch();
  const creditsData = useSelector(selectCreditData);
  const invoice = useSelector(selectSidebar);

  const { data, tableManager, value } = props;
  const { balance: invoiceBalance = 0, creditApplied: invoiceCreditApplied = 0, id: invoiceId } = invoice;
  const { balance, id, currencyCode } = data;
  const getInvoicesIds = tableManager.rowSelectionApi.selectedRowsIds;
  const addInvoice = size(filter(getInvoicesIds, (i) => i === id));

  const credit = creditsData?.credits?.find((i) => i.id === id);
  const { amount = 0, balanceLeft = 0 } = credit || {};
  const hasDifferentBalance = addInvoice && amount && amount !== balance;

  const invoiceBalanceLeft = invoiceBalance - invoiceCreditApplied + amount;
  const maxCreditBalanceLeft = invoiceBalanceLeft > balanceLeft + amount ? balanceLeft + amount : invoiceBalanceLeft;

  const currencyPrefix = getCurrencyPrefix(currencyCode);

  return (
    <div className="rgt-cell-inner text-truncate text-end d-flex align-items-center justify-content-end">
      <div className="hui-text-sm text-end me-2" hidden={!!addInvoice}>
        {formatCurrency(value, currencyCode)}
      </div>
      <div className="hui-text-sm text-end me-2 text-decoration-line-through" hidden={!hasDifferentBalance}>
        {formatCurrency(value, currencyCode)}
      </div>

      {!!addInvoice && (
        <div className="d-flex align-items-center hui-pay-invoices-total">
          <HuiInputAmount
            prefix={currencyPrefix}
            className="mb-0"
            id="amount"
            name="amount"
            placeholder={`${currencyPrefix} Type`}
            value={amount}
            onBlur={handleUpdateAmount}
            allowNegative={false}
            max={maxCreditBalanceLeft}
            onValidationChange={handleOnValidationChange}
          />
        </div>
      )}
    </div>
  );

  function handleUpdateAmount(e) {
    const value = e.target.value;
    const newAmount = parseFloat(value.replace(/\$|,/g, '')) || 0;
    handleOnValidationChange(e);
    updateAmount(newAmount);
  }

  function updateAmount(newAmount) {
    const creditsList = {
      credit,
      newAmount,
      creditInvoice: invoice,
    };
    dispatch(changeCreditTotal(creditsList));
    updateInvoiceAmount();
  }

  function updateInvoiceAmount() {
    const { creditApplied } = store.getState().creditData;
    dispatch(updateAmountWithCreditApplied(creditApplied));
  }

  async function handleOnValidationChange(event) {
    const { target: { hasError = false } = {} } = event || {};
    dispatch(updateCredit({ ...credit, hasError }));
  }
};

export default CreditMemoInputAmount;
