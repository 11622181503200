import { states as statesCA } from './utilsStatesCA';
import { states as statesUS } from './utilsStatesUS';

const STATE_MAP = {
  US: statesUS,
  CA: statesCA,
};

export function getStatesByCountry(country) {
  const states = STATE_MAP[country] || STATE_MAP['US'];
  return states;
}
