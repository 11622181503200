import { axiosInstance } from '../../utils/utilsAxios';

export async function getInvoicesSummary(params) {
  const url = `/v1/invoices/summary/all`;

  try {
    const response = await axiosInstance.get(url, { params });
    const summary = response.data;
    return summary;
  } catch (e) {
    throw new Error(e.message);
  }
}
