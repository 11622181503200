import { HuiIcon } from 'handle-ui';
import moment from 'moment/moment';
import { Link } from 'react-router-dom';
import HuiPaymentMethodIcon from '../../../components/payment/HuiPaymentMethodIcon';
import { formatCurrency } from '../../../utils/utilsCurrency';

const DashboardRecurrencesItem = (props) => {
  const { recurrence } = props;
  const { id, endDate, amount, currencyCode, type = 'Prepay', brand = 'VISA' } = recurrence;

  let label = 'Recurrent';

  const link = `/history/recurrences?id=${id}`;

  return (
    <Link to={link} title="View" className="hui-dashboard-payment-link">
      <div className="px-3 d-flex py-2 hui-dashboard-payment-item">
        <div className="flex-grow-1">
          <div className="hui-text fw-bold">{type}</div>
          <div className="hui-text hui-color-gray d-flex align-items-center">
            <div className="d-flex me-1">
              {label === 'Processing' ? <HuiIcon name="timer" size="xs" /> : <HuiIcon name="calendarDate" size="xs" />}
            </div>
            {label}
          </div>
        </div>

        <div className="d-flex flex-column align-items-end">
          <div className="hui-text d-flex align-items-center hui-dashboard-payment-amount">
            {formatCurrency(amount, currencyCode)}
            <HuiPaymentMethodIcon brand={brand} className="ms-2" />
          </div>
          <div className="hui-text hui-color-gray d-flex align-items-center">
            <div className="d-flex me-2">
              <HuiIcon name="reload" size="xs" />
            </div>
            {moment(endDate).format('MMM DD')}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default DashboardRecurrencesItem;
