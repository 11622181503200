import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCredits } from '../../../redux/CreditsSlice';
import { selectPaymentData } from '../../../redux/PaymentDataSlice';
import { selectPaymentReasons } from '../../../redux/PaymentReasonsSlice';
import { selectPortalInfo } from '../../../redux/PortalInfoSlice';
import { checkIfPaymentHasError } from '../../../utils/utilCheckIfPaymentHasError';
import PaymentSubtotalSelect from './PaymentSubtotalSelect';
import PaymentTotal from './PaymentTotal';
import { hasPaymentReasons } from './payment-form/utilPaymentForm';

const PaymentSubtotal = (props) => {
  const { rows: allCredits } = useSelector(selectCredits);
  const paymentData = useSelector(selectPaymentData);
  const paymentReasons = useSelector(selectPaymentReasons);
  const portalInfo = useSelector(selectPortalInfo);

  const { onClick } = props;

  const { openAccountTotal = null, subtotal = 0, usedCreditIds, currencyCode } = paymentData;

  const usedCredits = allCredits.filter((c) => usedCreditIds.includes(c.id));

  const credit =
    usedCredits.reduce((creditAmount, credit) => {
      return creditAmount + (credit.balance - credit.balanceLeft);
    }, 0) || 0;

  const [hasPaymentError, setHasPaymentError] = useState(false);

  useEffect(() => {
    function verifyPaymentHasError() {
      const payment = {
        invoices: paymentData.paymentInvoices,
        subtotal: paymentData.subtotal,
        hasOverpayReasons: hasPaymentReasons(paymentReasons, 'overpay'),
        hasShortpayReasons: hasPaymentReasons(paymentReasons, 'shortpay'),
      };
      const hasError = checkIfPaymentHasError(payment, portalInfo.settings);
      setHasPaymentError(hasError);
    }
    verifyPaymentHasError();
  }, [paymentReasons, portalInfo, paymentData, setHasPaymentError]);

  return (
    <>
      <div className="d-flex flex-column flex-md-row hui-payment-total">
        <div className="p-4 hui-bg-gray-light flex-grow-1 text-end d-none d-md-block">
          <PaymentTotal
            title="Outstanding Balance"
            currencyCode={currencyCode}
            amount={openAccountTotal}
            description="This balance represents the sum of all invoices that have not yet been paid on your account."
          />
        </div>

        <div className="p-4 hui-bg-white hui-payment-total-total">
          <PaymentSubtotalSelect
            onClick={onClick}
            viewCredits={viewCredits}
            disabled={hasPaymentError}
            currencyCode={currencyCode}
            subtotal={subtotal}
            credit={credit}
          />
        </div>
      </div>
    </>
  );

  function viewCredits() {}
};

export default PaymentSubtotal;
